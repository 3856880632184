const API_DOMAIN = "https://srm.uzdpart.com:33123"
const Whatsapp_DOMAIN = "https://srm.uzdpart.com:2800"

// This is for testing purposes
// const API_DOMAIN = "https://srm.uzdpart.com:7550"

const eventStatusMap = {
    'qr': 'qr',
    'loading_screen': 'loading_screen',
    'init_whatsapp': 'init_whatsapp',
    'session_restoring': 'session_restoring',
    'logging_in': 'logging_in',
    'qr_timeout': 'qr_timeout',
    'logged_in': 'logged_in',
    'logged_out': 'logged_out',
};

const wati_socket_url = "wss://live-mt-server.wati.io/5068/chat?access_token=";

const wati_filefetch_url = "https://live-mt-server.wati.io/5068/api/file/showFile?fileName=";

const API_ENDPOINTS = {
    status: 'whatsapp/status',
    init_qr: 'whatsapp/init_qr',
    logout: 'whatsapp/logout',
    device_stats: 'whatsapp/device_stats',
    get_customers: 'whatsapp/get_customers',
    get_suppliers: 'whatsapp/get_suppliers',
    get_querysets: 'whatsapp/get_querysets',
    get_prevdata_make: 'whatsapp/get_prevdata_make',
    get_makes: 'whatsapp/get_makes',
    get_models: 'whatsapp/get_models',
    add_model_v2: 'whatsapp/add_model_v2',
    add_makes_v2: 'whatsapp/add_makes_v2',
    get_makes_v2: 'whatsapp/get_makes_v2',
    get_models_v2: 'whatsapp/get_models_v2',
    get_querysets_wati: 'whatsapp/get_querysets_wati',
    get_supplier_tags: 'whatsapp/get_supplier_tags',
    add_supplier_tags: 'whatsapp/add_supplier_tags',
    delete_supplier_tags: 'whatsapp/delete_supplier_tags',
    get_suppliersV2: 'whatsapp/get_suppliersV2',
    add_suppliersV2: 'whatsapp/add_suppliersV2',
    add_suppliersV2: 'whatsapp/add_suppliersV2',
    add_queryset: 'whatsapp/add_queryset',
    add_querysetV2: 'whatsapp/add_querysetV2',
    exec_wa_one: 'whatsapp/exec_wa_one',
    update_queryset: 'whatsapp/update_queryset',
    customer_add: 'whatsapp/customer_add',
    region_add_update: 'whatsapp/region_add_update',
    get_region: 'whatsapp/get_region',
    delete_region: 'whatsapp/delete_region',
    delete_messages: 'whatsapp/delete_messages',
    disable_suppliersV2: 'whatsapp/disable_suppliersV2',
    get_wa_status: 'whatsapp/get_wa_status',

    //auth
    user_register: 'auth/register',
    user_login: 'auth/login',
    user_fetch_by_id: 'auth/fetchy/:id',
    user_exist_check: 'auth/exists/:id',
    get_all_users: 'auth/get_all_users',
    update_user: 'auth/update_user',
}

const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

function cleanStringKeepEmojis(input) {
    // This regex keeps numbers, letters, common special symbols, and emojis (which are part of Unicode's extended range)
    return input.replace(/[^\p{L}\p{N} !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\p{Emoji}]/gu, '');
}

function splitBySpecialChar(data) {
    // Use the Unicode value for the replacement character (U+FFFD)
    const specialChar = '';

    // Split the string by this character
    const splitData = data.split(specialChar);

    for (var i = 0; i < splitData.length; i++) {
        if (!splitData[i]) {
            splitData.splice(i)
        }
    }
    return splitData;
}

const country_codes = {
    AF: "Afghanistan",
    AX: "Åland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "AndorrA",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, The Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D\"Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic Of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People\"S Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People\"S Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, The Former Yugoslav Republic of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "RWANDA",
    SH: "Saint Helena",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    CS: "Serbia and Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
}

const string_sanitizing_regex = /^[a-zA-Z0-9,-.]*$/; // Letters, numbers, hyphens, and commas

module.exports = { API_DOMAIN, eventStatusMap, API_ENDPOINTS, capitalizeFirstLetterOfEachWord, string_sanitizing_regex, wati_socket_url, wati_filefetch_url, cleanStringKeepEmojis, splitBySpecialChar, Whatsapp_DOMAIN, country_codes };