import React, { useContext, useState, useEffect } from 'react';
import {
    LoginOutlined, MessageOutlined, PieChartOutlined,
    RobotOutlined, TeamOutlined, TruckOutlined, WhatsAppOutlined
} from '@ant-design/icons';
import { Layout, Menu, Spin, theme } from 'antd';
import { AuthContext } from './AuthContext';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, path) {
    return { key, icon, label: <Link to={path}>{label}</Link>, path, title: label };
}

const DrawerNew = () => {
    const { authState } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    // Define menu items
    const items = authState.status
        ? [
            getItem('Dashboard', '/dashboard', <PieChartOutlined />, '/dashboard'),
            getItem('Queryset V3', '/queryset', <WhatsAppOutlined />, '/queryset'),
            getItem('Bot Status', '/WhatsApp', <RobotOutlined />, '/WhatsApp'),
            getItem('WhatsApp Messages', '/whatsapp_messages', <MessageOutlined />, '/whatsapp_messages'),
            getItem('SRM', '/suppliers', <TruckOutlined />, '/suppliers'),
            getItem('HRM', '/hrm', <TeamOutlined />, '/hrm'),
        ]
        : [];

    const [collapsed, setCollapsed] = useState(false);      // Sidebar collapsed state
    const [selectedKey, setSelectedKey] = useState('');     // Menu key state
    const [currentHeader, setCurrentHeader] = useState(''); // Header title state
    const [isLoading, setIsLoading] = useState(true);       // Loading state

    // Handle auth status and navigation
    useEffect(() => {
        if (authState.status === undefined) {
            return;  // Wait for authState to load
        }

        if (!authState.status) {
            // Redirect to login if not authenticated
            navigate('/login');
        } else {
            // Navigate to the last visited page or default to '/dashboard'
            setIsLoading(false);
            const lastPage = location.pathname === '/login' ? '/queryset' : location.pathname;
            navigate(lastPage);
        }
    }, [authState.status, location.pathname, navigate]);

    // Set menu and header after authentication check
    useEffect(() => {
        if (!authState.status || isLoading) return;

        const setInitialMenuState = () => {
            const currentItem = items.find((item) => item.path === location.pathname);
            if (currentItem) {
                setSelectedKey(currentItem.key);        // Set selected menu key
                setCurrentHeader(currentItem.title);    // Set header title
            } else {
                setSelectedKey('');                     // No key if not found
                setCurrentHeader('Welcome');            // Default title
            }
        };

        setInitialMenuState();
    }, [location.pathname, items, authState.status, isLoading]);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    if (isLoading) {
        return (
            <Layout style={{ minHeight: '100vh', minWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size="large" />  {/* Loading spinner */}
            </Layout>
        );
    }
    setTimeout(() => {
        setCollapsed(true);
    }, 2000);
    return (
        <Layout style={{ minHeight: '100vh', minWidth: '100vw' }}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <div className="demo-logo-vertical" />
                <Menu
                    theme="dark"
                    selectedKeys={[selectedKey]}  // Controlled selected key after load
                    mode="inline"
                    items={items}
                />
            </Sider>
            <Layout>
                <Header style={{ padding: 10, background: colorBgContainer }}>
                    <h1 className="ml-5">{currentHeader}</h1> {/* Dynamic title */}
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    UzdPart Used Spare Parts Trading LLC © {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default DrawerNew;
