import React, { useEffect, useState, useRef, useContext, } from "react";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import green_tick from "../assets/images/green_tick.png";
import yellow_error from "../assets/images/yellow_error.png";
import whatsapp_logo from "../assets/images/whatsapp-96.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_DOMAIN, country_codes, eventStatusMap, string_sanitizing_regex } from "../components/constants";
import EditIcon from "@mui/icons-material/Edit";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as XLSX from "xlsx";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImportExport from "@mui/icons-material/ImportExport";
import socket from "../components/socket";
import { LicenseInfo } from "@mui/x-license-pro";
import SearchIcon from "@mui/icons-material/Search";
import { Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddLinkIcon from "@mui/icons-material/AddLink";
import SellIcon from "@mui/icons-material/Sell";
import useSnackbar from "../components/Snackbar/UseSnackbar";
import SettingsModal from "../components/SettingsModal/SettingsModal";
import { AuthContext } from "../components/AuthContext";
import { Select, InputNumber, Button, Dropdown, Timeline, Modal as AntdModal, Input as AntdInput, Space, DatePicker, Image, Upload, Collapse, Checkbox as AntdCheckbox, Table, Tooltip, Divider, Carousel, Skeleton, Tabs, Badge } from 'antd';
import { CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, MinusCircleTwoTone, PlusCircleTwoTone, PlusOutlined, PlusSquareOutlined, RetweetOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import wati_logo_only from '../assets/wati_logo_only.png'
import parsePhoneNumber from 'libphonenumber-js'
const { RangePicker } = DatePicker;
LicenseInfo.setLicenseKey(
  "e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);

pdfMake.vfs = pdfFonts.pdfMake.vfs;

var phoneToNameMap = [];
var supplierIdToName = [];

// Displaying the Users to the admin and their information
function Queryset() {
  //capitalize first letter of every word and lower case everything else
  function capitalizeWords(inputString) {
    if (typeof inputString !== "string") {
      throw new Error("Input must be a string, you nitwit!");
    }

    return inputString
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  const head = {
    headers: {
      accessToken: localStorage.getItem("accessToken")
    }
  };
  const { authState } = useContext(AuthContext);


  const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

  // The basic datasets
  const [querySet, setquerySet] = useState([]);
  const [postponedQuerySet, setPostponedQuerySet] = useState([]);
  const [querySetTable, setquerySetTable] = useState([]);
  const [usersSet, setUsersSet] = useState([]);
  const [makeSet, setmakeSet] = useState([]);
  const [brandSet, setbrandSet] = useState([]);
  const [modelSet, setModelSet] = useState([]);
  const [supplierSet, setsupplierSet] = useState([]);
  const [regionDataSet, setRegionDataSet] = useState([]);
  const [cus_name_set, set_cus_name_set] = useState([]);
  const [cus_name_set_for_select, set_cus_name_set_for_select] = useState([]);
  const [queryStatusSet, setQueryStatusSet] = useState([]);
  const [costPriceSet, setCostPriceSet] = useState([]);
  const [priceHistory, setPriceHistory] = useState([]);
  const [remarksSet, setRemarksSet] = useState([]);
  const [queryOriginSet, setqueryOriginSet] = useState([]);
  const [partsDataSet, setPartsDataSet] = useState([]);

  const [loading, setLoading] = useState(true);
  const [carLogoTypes, setCarLogoTypes] = useState([]);

  //Search parameters
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchUser, setSearchUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  //Custom modal to add stuff
  const [modalHeading, setModalHeading] = useState("Create New Query");
  const [modal_data, set_modal_data] = useState(null);
  const [cus_name_loading, set_cus_name_loading] = useState(true);

  //items for tab view data
  const [currentTabState, setCurrentTabState] = useState("All");



  //file upload misc states
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  //form data
  const [make_data, set_make_data] = useState([]);
  const [brand_data, set_brand_data] = useState(null);
  const [model_data, set_model_data] = useState(null);
  const [queryidEdit, set_queryid] = useState(null);
  const [model_details_data, set_model_details_data] = useState(null);
  const [cus_name, set_cus_name] = useState(null);
  const [year_data, set_year_data] = useState(null);
  const [chassisno_data, set_chassisno_data] = useState(null);
  const [cylinder_data, set_cylinder_data] = useState(null);
  const [bodypart_data, set_bodypart_data] = useState(null);
  const [phone_data, set_phone_data] = useState(null);
  const [region_data, set_region_data] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [WhatsappStatus, setWhatsappStatus] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editWhatsapp, setEditWhatsapp] = useState(false);
  const [suppCount, setSuppCount] = useState(0);
  const [queryModalTitle, setQueryModalTitle] = useState("Create new Query");
  const [queryMode, setQueryMode] = useState(null);
  const [queryOrigin, setQueryOrigin] = useState(null);
  const [followupMsg, setFollowupMsg] = useState(true);
  const [sendQidToWati, setSendQidToWati] = useState(true);



  const [defaultActiveKey, setDefaultActiveKey] = useState(1);



  //misc data
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(false);

  //references
  const file_DataRef = useRef(null);
  const queryDateRef = useRef(null);

  //Tab Focus to know if to send desktop notifications
  const [isTabFocused, setIsTabFocused] = useState(
    document.visibilityState === "visible"
  );
  const isTabFocusedRef = useRef(isTabFocused);

  const [expandedRows, setExpandedRows] = useState({});

  const handleExpandClick = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  // These are for the Modals
  //Query adding modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setSendQidToWati(true);
    setIsQsDisabled(false);
    setSuppCount(0);
    if (WhatsappStatus.status === "logged_in") {
      reload_customers();
      setOpen(true);
    } else alert("Please resolve WhatsApp Bot issues before making a query.");
  };
  const handleClose = () => {
    setModalHeading("Create New Query");
    setPriceHistory(null);
    //this will reload all the queries
    reloadQuerySet();
    // document.getElementById("sup_count").style.display = "none";
    set_make_data(null);
    setModelSet(null);
    set_brand_data(null);
    set_year_data(null);
    set_model_data(null);
    set_model_details_data(null);
    set_chassisno_data(null);
    set_cylinder_data(null);
    set_bodypart_data(null);
    set_phone_data(null);
    set_region_data(null);
    setFileList([]);
    setQueryOrigin(null);
    setEdit(false);
    set_cus_name(null);
    setSuppCount(0);
    setIsQsDisabled(false);
    setEdit(false)
    setEditWhatsapp(false)
    set_queryid(null)
    setQueryMode(null)
    setTimeout(() => {
      if (file_DataRef.current) {
        file_DataRef.current.disabled = true;
      }
    }, 0);
    setQueryModalTitle("Create new Query");
    setOpen(false);
  };

  const send_wati_general_message = async (phone, message) => {
    try {
      var response = await axios.post(API_DOMAIN + "/whatsapp/send_wati_general_message", { phone: phone, message: message, user: authState.id, }, head);
      if (response.data.success) {
        SuccessSnackbar("Message sent successfully!");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      alert("Failed to send message! Try again later\n", error.message);
    }
  };

  const handleSendWatiLink = async () => {
    try {
      var message = [];
      const phone = dataLink.phone;

      message.push(`Dear ${currentQueryData.cus_name}, \nPlease find the link-to-pay link generated below, to proceed with the payment.\n\n${theLink}\n\nDont Forget to share the screenshot.\n\nThank you for choosing UZDpart!`);

      var response = await send_wati_general_message(phone, message);

      if (response) {
        document.getElementById("link_gen_send_wati").style.display = "none";
        setOpenLinkGen(false);
      } else {
        ErrorSnackbar("Failed to send message! Try again later.");
      }
    } catch (error) {
      ErrorSnackbar("Failed to send message! Try again later\n", error.message);
    }
  };

  //Link generation model
  const [dataLink, setDataLink] = useState({});
  const [openLinkGen, setOpenLinkGen] = useState(false);
  const handleOpenLinkGen = (data) => {
    setDataLink(data);
    setLinkDescription(`${data.body_part}`);
    setLinkTitle(`${data.make}-${data.model}`);
    setLinkAmount(data.price_given);
    if (data.status != "Confirmed") {
      if (
        window.confirm('This query is not yet set to "Confirmed". Do you want to proceed?\nThis will change the status to "Confirmed & Paid" automatically')
      ) {
        setOpenLinkGen(true);
      }
    } else {
      setOpenLinkGen(true);
    }
  };
  const handleCloseLinkGen = () => {
    document.getElementById("link_gen_send_wati").style.display = "none";
    setOpenLinkGen(false);
    setDataLink({});
    setTheLink("");
  };

  const handleLinkGen = async () => {
    if (!linkTitle) {
      WarningSnackbar("Please enter a valid Title");
      return;
    }
    if (!linkAmount) {
      WarningSnackbar("Please enter a valid Amount");
      return;
    }
    try {
      var response = await axios.post(API_DOMAIN + "/whatsapp/get_payByLink_link", { query_id: dataLink.query_id, title: linkTitle, description: linkDescription, amount: linkAmount, processing_fee: linkFeePercent, }, head);
      if (response.data.success) {
        setTheLink(response.data.data.payment_url);
        const dataLink_copy = {
          ...dataLink,
          moredetails: {
            ...dataLink.moredetails,
            payment_details: response.data.data,
          },
        };
        setDataLink(dataLink_copy);

        SuccessSnackbar("PayByLink generated successfully!");
        document.getElementById("link_gen_send_wati").style.display = "flex";

        // await axios.post(API_DOMAIN + "/whatsapp/update_querysetV2", dataLink);
      }
    } catch (error) {
      ErrorSnackbar(
        "Failed to generate PayByLink link! Try again later\n",
        error.message
      );
    }
  };

  const [linkTitle, setLinkTitle] = useState("");
  const [linkDescription, setLinkDescription] = useState("");
  const [linkAmount, setLinkAmount] = useState("");
  const [isQsDisabled, setIsQsDisabled] = useState(false);
  const [linkFeePercent, setLinkFeePercent] = useState("");

  const setLinkTitleHandler = (title) => {
    if (linkTitle.length <= 50) {
      if (string_sanitizing_regex.test(title)) {
        setLinkTitle(title);
        return;
      }
    }
    InfoSnackbar("Title can't be more than 50 characters and can't contain special characters, you can use hyphens, commas and periods only!");
  };

  const setLinkDescriptionHandler = (title) => {
    if (linkDescription.length <= 100) {
      if (string_sanitizing_regex.test(title)) {
        setLinkDescription(title);
        return;
      }
    }
    InfoSnackbar("Dedscription can't be more than 100 characters and can't contain special characters, you can use hyphens, commas and periods only!");
  };

  const handleResetField = (fieldName) => {
    switch (fieldName) {
      case "linkTitle":
        setLinkTitle("");
        break;
      case "linkDescription":
        setLinkDescription("");
        break;
      case "linkAmount":
        setLinkAmount("");
        break;
      case "linkFeePercent":
        setLinkFeePercent("");
        break;
      default:
        alert("You went out of bounds.")
        break;
    }
  }


  const [theLink, setTheLink] = useState("");

  //Adding stuff to DB modal
  const [open_add_modal, setAddModalState] = useState(false);
  const handleOpenAddModal = (title) => {
    if (
      title == "Add New Model" &&
      (brand_data == null || brandSet.some((element) =>
        element["label"] == "" ? false : element["label"] == brand_data))
    ) {
      ErrorSnackbar("Please select a 'Make' first, to add a new model");
      return;
    } else {
      setModalHeading(
        title == "Add New Model"
          ? "Add New '" + brandSet.find((value) => value.value == brand_data).label + "' Model"
          : title
      );
      set_modal_data("");
      setAddModalState(true);
    }
  };
  const handleCloseAddModal = () => {
    set_modal_data("");
    setModalHeading("");
    setAddModalState(false);
  };

  //Settings modal states
  const [open_settings, setSettings] = useState(false);
  const handleOpenSettingsModal = () => {
    setSettings(true);
  };
  const handleCloseSettingsModal = () => {
    setSettings(false);
  };

  //Settings modal states
  const [currentQueryData, setCurrentQueryData] = useState({});
  const [triggerAction, setTriggerAction] = useState(null);
  const [openQuickReplies, setOpenQuickReplies] = useState(false);
  const handleOpenQuickReplies = (data) => {
    setCurrentQueryData(data);
    setOpenQuickReplies(true);
  };
  const handleCloseQuickReplies = () => {
    setOpenQuickReplies(false);
  };

  const [openQuickRepliesEdit, setOpenQuickRepliesEdit] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const handleOpenQuickRepliesEdit = (templateId) => {
    if (templateId) {
      const foundTemplate = templateTemp.find(
        (template) => template.id === templateId
      );
      if (foundTemplate) {
        var processedText = foundTemplate.text.replaceAll("{{name}}", currentQueryData.cus_name);
        processedText = processedText.replaceAll("{{query_id}}", currentQueryData.query_id);
        processedText = processedText.replaceAll("{{partname_value}}", currentQueryData.body_part);
        processedText = processedText.replaceAll("{{price_value}}", priceValues[currentQueryData.query_id]["price_given"]);
        processedText = processedText.replaceAll("{{make}}", currentQueryData.make);
        processedText = processedText.replaceAll("{{number}}", currentQueryData.phone);
        processedText = processedText.replaceAll("{{warranty}}", priceValues[currentQueryData.query_id]["warranty"]);
        processedText = processedText.replaceAll("{{operator.first_name}}", authState.name);
        setReplyMessage(processedText); // State update
        setOpenQuickRepliesEdit(true); // Safely called after setReplyMessage

      }
    }
  };
  const handleCloseQuickRepliesEdit = () => {
    setReplyMessage("");
    setOpenQuickRepliesEdit(false);
  };

  const search_quickresponses = (event) => {
    var value = event.target.value;
    var temp = templates.filter((data) => {
      return (
        data.name.toLowerCase().includes(value.toLowerCase()) ||
        data.text.toLowerCase().includes(value.toLowerCase())
      );
    });
    setTemplatesTemp(temp);
  };

  const handleSendWatiTemplate = async () => {
    try {
      // var message = [];
      const phone = currentQueryData.phone;

      // message.push(replyMessage);

      var response = await send_wati_general_message(phone, replyMessage);

      if (response) {
        SuccessSnackbar("Message sent successfully!");
        setReplyMessage("");
        handleCloseQuickRepliesEdit();
      } else {
        ErrorSnackbar("Failed to send message! Try again later.");
      }
    } catch (error) {
      ErrorSnackbar(
        "Failed to send message! Try again later\n",
        error.message
      );
    }
  };

  //handling onclicks
  const handling_setquerySet = (data) => {
    const datasetMap = {};
    const initialPriceValues = {};

    data.forEach((dataset, index) => {
      dataset.key = index;
      dataset.price_percentage =
        (
          ((dataset.price_given -
            dataset.best_price_received -
            dataset.delivery_cost ?? 0) /
            dataset.best_price_received) *
          100
        ).toFixed(2) === "NaN"
          ? 0
          : (
            ((dataset.price_given -
              dataset.best_price_received -
              dataset.delivery_cost ?? 0) /
              dataset.best_price_received) *
            100
          ).toFixed(2);

      datasetMap[dataset.query_id] = dataset;

      initialPriceValues[dataset.query_id] = {
        best_price_received: dataset.best_price_received || 0,
        price_percentage: dataset.price_percentage || 0,
        price_given: dataset.price_given || 0,
        status: dataset.status,
        delivery_cost: dataset.delivery_cost
          ? { DeliveryCost: dataset.delivery_cost }
          : { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 },
        statusValue:
          dataset.status == "postponed"
            ? new Date(dataset?.moredetails?.statusValue)
            : dataset?.moredetails?.statusValue || null,
        remarks: dataset?.moredetails?.remarks || "",
        f_date: dayjs(dataset?.moredetails?.f_date) || undefined,
        warranty: dataset?.moredetails?.warranty || 0,
      };
    });

    setquerySet(data);
    setTimeout(() => {
      filter_queryset_tabchange(currentTabState, data);
    }, 0);
    setQueryDataMap(datasetMap);
    setTimeout(() => {
      setPriceValues(initialPriceValues);
    }, 0);
  };
  const handleQueryValueChange = (queryId, field, value) => {
    setQueryDataMap({
      ...queryDataMap,
      [queryId]: {
        ...queryDataMap[queryId],
        [field]: value,
      },
    });
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleFileChange = (info) => {
    let newFileList = [...info.fileList];

    newFileList.map((file) => {
      if (file?.response) {
        return file.response;
      }
    });
    setFileList(newFileList);
    if (info.file.status === 'done') {
      setIsQsDisabled(false);
    }
  };
  const beforeUpload = (file) => {
    setIsQsDisabled(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      WarningSnackbar('You can only upload JPG/PNG file!');
      setIsQsDisabled(false);
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      WarningSnackbar('Image must smaller than 1MB!');
      setIsQsDisabled(false);
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
        color: 'black'
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const handleSubmit = (send_wa = false, save = true) => {
    //split the below if condition to check for each field
    if (make_data && make_data.length == 0) {
      WarningSnackbar("Please Select one Supplier group minimum");
      return;
    }
    if (!brand_data) {
      WarningSnackbar("Please Select a Make");
      return;
    }
    if (!queryOrigin) {
      WarningSnackbar("Please Select the origin of the query");
      return;
    }
    if (!model_data) {
      WarningSnackbar("Please Select a Model");
      return;
    }
    if (!bodypart_data) {
      WarningSnackbar("Please Enter a PartName");
      return;
    }
    if (edit ? false : !fileList) {
      WarningSnackbar("Please Select an Image");
      return;
    }
    if (!year_data) {
      WarningSnackbar("Please Select a Year");
      return;
    }
    if (!phone_data) {
      WarningSnackbar("Please add customer mobile number");
      return;
    }
    if (chassisno_data && chassisno_data.length < 17) {
      WarningSnackbar("Please Enter a Valid Chassis Number");
      return;
    }
    if (fileList.length == 0) {
      WarningSnackbar("Please Add Image(s)");
      return;
    }

    if (followupMsg) {
      window.confirm("Are you sure to schedule auto-replies for this query?") ? setFollowupMsg(true) : setFollowupMsg(false)
    }

    followupMsg ? InfoSnackbar("Scheduling auto-replies for this query...") : InfoSnackbar("Skipping auto-replies for this query...");


    // Proceed with your form submission or further processing
    const formData = {
      make: make_data,
      chassisNo: chassisno_data,
      brand: brand_data,
      model: model_data,
      cylinders: cylinder_data?.toString().trim() || "",
      model_details: model_details_data?.toString().trim(),
      year: year_data ? dayjs(year_data).format("YYYY") : "",
      region: region_data,
      bodypart: bodypart_data,
      queryOrigin: queryOrigin,
      phone: phone_data.toString().trim(),
      cus_name: cus_name,
      image: fileList.map((file) => file?.response?.replace('/', '') ? file?.response?.replace('/', '') : file?.url?.replace(`${API_DOMAIN}/`, '')),
      user: authState.id,
      user_name: authState.name,
      queryMode: queryMode,
      queryidEdit: queryidEdit,
      followupMsg: followupMsg,
      sendQidToWati: sendQidToWati,
      send_wa,
      save
    };

    InfoSnackbar("Sending query....");
    setIsQsDisabled(true);
    axios.post(API_DOMAIN + "/whatsapp/add_querysetV3", { formData, api_secret: "uzdpart" }, head)
      .then((res) => {
        if (res.data.success) {
          setPriceHistory([]);
          setIsQsDisabled(false);
          if (followupMsg) SuccessSnackbar("Auto-replies Scheduled Successfully!");
          if (isTabFocusedRef.current) {
            SuccessSnackbar("Query ID " + res?.data?.data[0]?.query_id + " processed successfully!");
            //this is to initiate the automatic message sending to WATI 

            // window.confirm("DO you want to send the 'Query ID details' message to WATI?")
          } else {
            showNotification("Query (" + res?.data?.data[0]?.query_id + ") Succeeded", "Query with ID " + res?.data?.data[0]?.query_id + " has been processed successfully!", null);
          }
          handleClose();
        } else {
          if (isTabFocusedRef.current) {
            ErrorSnackbar("Query ID " + res?.data?.data[0]?.query_id + " Failed to Process!\nPlease try again");
            ErrorSnackbar(res.data.message);
          } else {
            showNotification("Query (" + res?.data?.data[0]?.query_id + ") Failed", "Query with ID " + res?.data?.data[0]?.query_id + " has Failed to process!\nPlease try again later.", null);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorSnackbar("Failed to process Queryset! Try again later\n", error.message);
        setIsQsDisabled(false);
        document.getElementById("send_query_btn").innerHTML = "Try Again...";
      }).finally(() => {
        // Enable the button again whether success or failure
        setIsQsDisabled(false);
        document.getElementById("send_query_btn").innerHTML = "Try Again...";
      });
  };
  const handleQueryEdit = (data, mode = "duplicate") => {
    setEdit(true);
    if (data.phone)
      setTimeout(() => {
        handleCusMobChange(data.phone);
      }, 0);
    set_make_data([]);
    setModelSet([]);
    try { data.sup_set = JSON.parse(data.sup_set); } catch (error) { }

    set_queryid(data.query_id);
    setQueryOrigin(data?.moredetails?.queryOrigin || null);
    set_make_data(data?.sup_set);
    set_brand_data(data?.make);
    load_models(data?.make);
    set_model_data(data?.model);
    set_model_details_data(data?.model_details);
    set_bodypart_data(data?.body_part);
    set_year_data(dayjs(new Date(`${data?.year}-01-01`)).format("YYYY-MM-DD"));
    set_chassisno_data(data?.chassis_no);
    set_cylinder_data(data?.cylinder);
    set_region_data(data?.region);
    set_cus_name(data?.cus_name);
    set_phone_data(data?.phone);
    setFileList(data?.image.map((img) => ({ url: API_DOMAIN + '/' + img })));
    setQueryMode(mode);
    setTimeout(() => {
      if (file_DataRef.current) {
        file_DataRef.current.disabled = true;
      }
      handleOpen();
    }, 0);
  };
  const set_data_to_edit_to_modal = (data) => {
    setTimeout(() => {
      handleCusMobChange(data?.phone);
    }, 0);
    set_make_data([]);
    setModelSet([]);
    setQueryOrigin(data?.moredetails?.queryOrigin[0]?.value || null);
    set_make_data(typeof data?.sup_set == 'string' ? JSON.parse(data?.sup_set).map(e => e.value) : data?.sup_set);
    const brand_id = data?.make ? brandSet.filter((e) => e.label == data?.make)[0].value : null;
    set_brand_data(brand_id);
    if (brand_id) load_models(brand_id, data?.model);
    //due to async nature of setState modelSet doesn't load in a timely fashion so setting model value has been moved into load_models
    set_model_details_data(data?.model_details);

    set_bodypart_data(data?.body_part ? partsDataSet.filter((e) => data?.body_part.split(",").includes(e.label)).map(e => e.value) : null);
    set_year_data(dayjs(new Date(`${data?.year}-01-01`)).format("YYYY-MM-DD"));
    set_chassisno_data(data?.chassis_no);
    set_cylinder_data(data?.cylinder);
    set_region_data(data?.region ? regionDataSet.filter((e) => e.label == data?.region)[0].value : null);
    set_cus_name(data?.cus_name);
    set_phone_data(data?.phone);
    setFileList(data?.image ? data?.image.map((img) => ({ url: API_DOMAIN + '/' + img })) : null);
    handleOpen();
  }
  const handleQueryDuplicate = (data) => {
    setModalHeading(`Cloning the query (${data?.query_id}) To create a new one`);
    set_data_to_edit_to_modal(data);
  }
  const handleFocus = (event) => {
    event.target.select();
  };
  const handleKeyPress = (event, queryId, field) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      if (field === "best_price_received") {
        handleQueryUpdate("", false, "", false, "", false, value, true, queryId, "", false, false);
      } else if (field === "price_given") {
        handleQueryUpdate("", false, "", false, value, true, "", false, queryId, "", false, false);
      }
    }
  };
  const handleQueryUpdate = (supplier, bSupplier, status, bStatus, price_given, bPrice_given, best_price_received, bBest_price_received, query_id, deliveryCost, bdeliveryCost, mdetails, bmdetails, disable, remarks, bremarks, f_date, bf_date, warranty, bwarranty) => {
    // document.getElementById('price_percentage' + query_id).disabled = true
    // document.getElementById('delivery_cost' + query_id).disabled = true
    // document.getElementById('price_given' + query_id).disabled = true
    if (bStatus && status == "Postponed") mdetails.statusValue = null;
    axios.post(API_DOMAIN + "/whatsapp/update_queryset", { supplier, bSupplier, status, bStatus, price_given, bPrice_given, best_price_received, bBest_price_received, query_id, deliveryCost, bdeliveryCost, mdetails, bmdetails, disable, remarks, bremarks, f_date, bf_date, warranty, bwarranty }, head).then((res) => {
      setQueryDataMap((prevData) => ({
        ...prevData,
        [query_id]: {
          ...prevData[query_id],
          ...(supplier !== "" && { supplier }),
          ...(status !== "" && { status }),
          ...(price_given !== "" && { price_given }),
          ...(best_price_received !== "" && { best_price_received }),
          ...(deliveryCost !== "" && { deliveryCost }),
          ...(mdetails !== "" && { mdetails }),
        },
      }));
      if (status) {
        handlePriceValueChanges(query_id, "status", status);
        var tempData = querySet;
        for (let i = 0; i < tempData.length; i++) {
          if (tempData[i].query_id == query_id) {
            tempData[i].status = status;
            break;
          }
        }
        setquerySet(tempData);

      }
      // document.getElementById('price_percentage' + query_id).disabled = false
      // document.getElementById('delivery_cost' + query_id).disabled = false
      // document.getElementById('price_given' + query_id).disabled = false

      SuccessSnackbar("Updated Queryset successfully!");
      //removing whatsapp edit mode
      setEditWhatsapp(false);

      //after contemplation i found this is not necessary as the current data is already updated both in front and backend
      //only seems to make the UI slower
      // reloadQuerySet();
    })
      .catch((error) => {
        // document.getElementById('price_percentage' + query_id).disabled = false
        // document.getElementById('delivery_cost' + query_id).disabled = false
        // document.getElementById('price_given' + query_id).disabled = false
        // Handle error if needed
      });
  };
  const handleUnsend = (query_id) => {
    axios
      .post(
        API_DOMAIN + "/whatsapp/delete_messages",
        { api_secret: "uzdpart", query_id },
        head
      )
      .then((res) => {
        if (res.data.success) {
          SuccessSnackbar("Unsent Queryset successfully!");
        } else {
          ErrorSnackbar("Unsent Queryset Failed!\nPlease try again later!");
        }
        reloadQuerySet();
      })
      .catch((error) => {
        // Handle error if needed
      });
  };
  const handleMakeChange = (value) => {
    if (value && value.length > 0) {
      axios.post(API_DOMAIN + "/whatsapp/get_suppliers", { make: value, api_secret: "uzdpart" }, head)
        .then((res) => {
          if (res.data.success) {
            setSuppCount(res.data.data.length);
          }
        });
    } else {
      setSuppCount(0);
    }
  };
  const handleBrandChange = (value) => {
    set_model_data(null);
    set_brand_data(value);
    if (value) {
      load_models(value);
    } else {
      setModelSet([]);
      set_model_data(null);
    }
  };
  const handle_model_change = (value) => {
    set_model_data(value);
    axios.post(API_DOMAIN + "/whatsapp/get_last_month_prices", { make: brand_data, model: value }, head)
      .then((res) => {
        if (res.data.success) {
          setPriceHistory(res.data.data);
        }
      });
  };
  const handleCusNameChange = (cusId) => {
    set_cus_name(cusId);
    var mobile = null;
    if (cusId)
      var mobile = cus_name_set.find((cus_data) => cus_data.customer_id == cusId).customer_mobile;
    set_phone_data(mobile);
  };

  async function waitForLoadingToFinish() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!cus_name_loading) {
          clearInterval(interval);
          resolve();
        }
      }, 100); // Check every 100ms
    });
  }

  async function handleCusMobChange(phone) {
    await waitForLoadingToFinish(); // Wait until loading is done

    if (phone.length >= 6) {
      const customerData = cus_name_set.find(
        (customer) => customer.customer_mobile === phone
      );
      if (customerData) {
        set_cus_name(customerData.customer_id);
      } else {
        set_cus_name(null);
      }
    } else {
      set_cus_name(null);
    }
  }

  const handleExcelClick = () => {
    generateExcel(querySetTable);
  };
  const handlePdfClick = () => {
    generatePDF(querySetTable);
  };

  const handleModalAdd = (e, title) => {
    if (!modal_data) {
      WarningSnackbar("Please Enter a Value first");
      return;
    }
    switch (title) {
      case "Add New Region":
        axios.post(API_DOMAIN + "/whatsapp/region_add_update", { region: modal_data }, head).then((res) => {
          if (res.data.success) {
            const regionTemp = res.data.data.map((item) => ({
              value: item.id,
              label: item.region,
            }));
            setRegionDataSet(regionTemp);
            set_region_data(modal_data);
            SuccessSnackbar("Region added successfully");
            handleCloseAddModal();
          }
        });
        break;
      case "Add New Make":
        axios.post(API_DOMAIN + "/whatsapp/add_makes_v2", { name: modal_data }, head).then((res) => {
          if (res.data.success) {
            const makeSetTemp = res.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            setbrandSet(makeSetTemp);
            set_brand_data(modal_data);
            SuccessSnackbar("Make added successfully");
            handleCloseAddModal();
          } else {
            ErrorSnackbar(res.data.message);
          }
        });
        break;
      case "Add New '" + brandSet.find((value) => value.value == brand_data).label + "' Model":
        axios.post(API_DOMAIN + "/whatsapp/add_model_v2", { make: brand_data, model: modal_data }, head).then((res) => {
          if (res.data.success) {
            const ModelTemp = res.data.data.map((item) => ({
              value: item.id,
              label: item.model,
            }));
            setModelSet(ModelTemp);

            set_model_data(ModelTemp.find((element) => element.label == modal_data).value);
            SuccessSnackbar("Model added successfully");
            handleCloseAddModal();
          } else {
            WarningSnackbar(res.data.message);
          }
        });
        break;
      case "Add New Part":
        axios.post(API_DOMAIN + "/whatsapp/add_new_part_info", { value: modal_data }, head).then((res) => {
          if (res.data.success) {
            setPartsDataSet(res.data.data);
            SuccessSnackbar("Part Info added successfully");
            handleCloseAddModal();
          } else {
            ErrorSnackbar(res.data.message);
          }
        });
        break;
      case "Add New Query origin":
        axios.post(API_DOMAIN + "/whatsapp/add_new_query_origin", { value: modal_data, mode: "query_source" }, head).then((res) => {
          if (res.data.success) {
            setqueryOriginSet(res.data.data);
            SuccessSnackbar("Query origin added successfully");
            handleCloseAddModal();
          } else {
            ErrorSnackbar(res.data.message);
          }
        });
        break;
      default:
        break;
    }
  };
  const handleRegionChange = (value) => {
    set_region_data(value);
  };
  const onSearchDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  //miscellaneous functions
  const showNotification = (main, body, logo) => {
    if (Notification.permission === "granted") {
      new Notification(main ? main : "Hello!", {
        body: body ? body : "This is a system-level notification.",
        icon: logo
          ? logo
          : "https://www.uzdpart.com/wp-content/themes/uzdpart-child/assets/images/logo.PNG",
      });
    } else {
      console.log("Notification permission not granted.");
    }
  };
  const generatePDF = (data) => {
    if (data.length > 0) {
      SuccessSnackbar(
        `Preparing PDF from ${dayjs(startDate).format("DD-MM-YYYY")} to ${dayjs(
          endDate
        ).format("DD-MM-YYYY")} ${currentTabState != "" ? "with status " + currentTabState : "."
        } contains ${data.length} rows.`
      );
      const docDefinition = {
        pageSize: "A3",
        pageOrientation: "landscape",
        content: [
          {
            text: `Results between (${dayjs(startDate).format(
              "YYYY-MM-DD"
            )} ~ ${dayjs(endDate).format("YYYY-MM-DD")}), Status : ${currentTabState ? currentTabState : "<All Statuses>"
              }, Total Rows : ${data.length}`,
            margin: [0, 20, 0, 8],
          },
          {
            style: "tableExample",
            table: {
              body: [
                [
                  { text: "SlNo", style: "tableHeader" },
                  { text: "Query ID", style: "tableHeader" },
                  { text: "User", style: "tableHeader" },
                  { text: "Date", style: "tableHeader" },
                  { text: "Make", style: "tableHeader" },
                  { text: "Model", style: "tableHeader" },
                  { text: "Year", style: "tableHeader" },
                  { text: "Part", style: "tableHeader" },
                  { text: "Customer Name", style: "tableHeader" },
                  { text: "Phone", style: "tableHeader" },
                  { text: "Supplier", style: "tableHeader" },
                  { text: "Best Price Received", style: "tableHeader" },
                  { text: "Price Given", style: "tableHeader" },
                  { text: "Status-Remarks", style: "tableHeader" },
                ],
                ...data.map((item, key) => [
                  key + 1,
                  item.query_id,
                  item.user,
                  item.date,
                  item.make,
                  item.model,
                  item.year,
                  item.body_part,
                  item.cus_name || "N/A",
                  item.phone || "N/A",
                  item.supplier != null &&
                    item.supplier != "" &&
                    item.supplier != undefined
                    ? supplierIdToName[item.supplier] ?? "N/A"
                    : "N/A",
                  item.best_price_received,
                  item.price_given,
                  item.status,
                ]),
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 5],
                },
                tableExample: {
                  margin: [0, 5, 0, 15],
                },
                tableHeader: {
                  bold: true,
                  fontSize: 13,
                  color: "black",
                },
              },
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 && rowIndex != 0
                  ? "#CCCCCC"
                  : rowIndex == 0
                    ? "#2AFD00"
                    : null;
              },
            },
          },
        ],
      };

      pdfMake.createPdf(docDefinition).download(`queries_${dayjs(startDate).format("YYYY-MM-DD")}_${dayjs(endDate).format("YYYY-MM-DD")}${currentTabState ? "_" + currentTabState.replaceAll(" ", "_") : ""}.pdf`);
      //not needed as almost all modern browsers open PDFs directly automatically after downloading
      // pdfMake.createPdf(docDefinition).open();
    } else {
      alert(
        "Refine your search conditions to have some results in-order to generate PDF."
      );
    }
    return;
  };
  const generateExcel = (data) => {
    if (data.length > 0) {
      SuccessSnackbar(
        `Exporting data to Excel format, from ${dayjs(startDate).format(
          "DD-MM-YYYY"
        )} to ${dayjs(endDate).format("DD-MM-YYYY")}${currentTabState != "" ? ", with status " + currentTabState : "."
        } contains ${data.length} rows.`
      );
      // Convert data to worksheet format
      const worksheetData = [
        [
          "SlNo",
          "Query ID",
          "User",
          "Date",
          "Make",
          "Model",
          "Year",
          "Part",
          "Customer Name",
          "Phone",
          "Supplier",
          "Best Price Received",
          "Price Given",
          "Status-Remarks",
        ],
        ...data.map((item, key) => [
          key + 1,
          item.query_id,
          item.user,
          item?.timeline[0]
            ? (() => {
              const [key, val] = Object.entries(item.timeline[0])[0];
              return val;
            })()
            : item.date,
          item.make,
          item.model,
          item.year,
          item.body_part,
          item.cus_name || "N/A",
          item.phone || "N/A",
          item.supplier != ""
            ? supplierIdToName[item.supplier] ?? "N/A"
            : "N/A",
          item.best_price_received,
          item.price_given,
          item.status,
        ]),
      ];

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Queries");

      // Format the filename
      const filename = `queries_${dayjs(startDate).format(
        "YYYY-MM-DD"
      )}_${dayjs(endDate).format("YYYY-MM-DD")}${currentTabState ? "_" + currentTabState.replaceAll(" ", "_") : ""
        }.xlsx`;

      // Write workbook and download
      XLSX.writeFile(workbook, filename);
    } else {
      alert(
        "Refine your search conditions to have some results in-order to export to Excel."
      );
    }
  };

  function phonenumberformat(number) {
    number = number.replace(/[^0-9]/g, "");
    if (number.length === 10) {
      if (number.startsWith("05")) {
        number = number.replace("05", "9715");
      } else {
        number = "91" + number;
      }
    }
    number = number + "@c.us";
    return number;
  };

  const reload_cost_prices = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_costprices", head).then((res) => {
      if (res.data.success) {
        setCostPriceSet(res.data.data);
      }
    });
  };
  const reload_customers = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_customers", head).then(async (res) => {
      if (res.data.success) {
        set_cus_name_set(res.data.data);
        set_cus_name_loading(true);

        const filtered_for_select = await Promise.all(
          res.data.data.map(async (customer) => {
            return {
              value: customer.customer_id,
              label: customer.customer_name,
            };
          })
        );
        set_cus_name_set_for_select(filtered_for_select);
        set_cus_name_loading(false);
      }
    });
  };
  const reload_suppliers = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_suppliersV2", head).then((res) => {
      setsupplierSet(res.data.data);
      // console.log("res.data.data = " + JSON.stringify(res.data.data));
      var supSet = supplierSet;
      phoneToNameMap = supSet.reduce((map, supplier) => {
        map[phonenumberformat(supplier.number)] = supplier.name;
        return map;
      }, {});
      supplierIdToName = supSet.reduce((map, supplier) => {
        map[supplier.id] = supplier.name;
        return map;
      }, {});
    });
  };
  const reload_makes = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_supplier_tags", head).then((res) => {
      if (res.data.success) {
        var makeSetTemp = [];
        // const transformedQuerySet = makeSetTemp.map(item => item.name);
        for (let i = 0; i < res.data.data.length; i++) {
          makeSetTemp.push({
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          });
        }
        setmakeSet(makeSetTemp);
      }
    });
  };
  const reload_querysets = () => {
    var from = dayjs(startDate).format("YYYY-MM-DD");
    var to = dayjs(endDate).format("YYYY-MM-DD");
    setLoading(true);
    axios.post(API_DOMAIN + "/whatsapp/get_querysets", { from, to, id: authState.id }, head).then((res) => {
      setLoading(false);
      if (res.data.success) {
        handling_setquerySet(res.data.data);
      }
    })
      .catch((error) => {
        console.error("Error reloading querysets:", error);
        setLoading(false);
      });

    // setTimeout(() => {
    //     reload_querysets(head);
    // }, 5000); //repeat call after 3 minutes (180000 milliseconds)
  };
  const reload_region = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_region", head).then((res) => {
      const regionTemp = res.data.data.map((item) => ({
        value: item.id,
        label: item.region,
      }));
      setRegionDataSet(regionTemp);
    });
  };
  const reload_brands = async (value = "") => {
    try {
      set_model_data(null);
      const res = await axios.post(API_DOMAIN + "/whatsapp/get_makes_v2", {
        name: value,
      }, head);
      if (res.data.success) {
        const makeSetTemp = res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setbrandSet(makeSetTemp);
      }
    } catch (error) {
      console.error("Error reloading brands:", error);
    }
  };
  const load_models = (value, model = null) => {
    axios.post(API_DOMAIN + "/whatsapp/get_models_v2", { make: value }, head)
      .then((res) => {
        if (res.data.success) {
          const ModelTemp = res.data.data.map((item) => ({
            value: item.id,
            label: item.model,
          }));
          setModelSet(ModelTemp);
          if (model) {
            set_model_data(ModelTemp.filter((e) => e.label == model)[0].value);
          }
        }
      });
  };
  const load_users = () => {
    axios.get(API_DOMAIN + "/whatsapp/get_users", head).then((res) => {
      if (res.data.success) {
        setUsersSet(res.data.data.map(element => ({ value: element.id, label: element.name })));
      }
    });
  };

  const computePriceToPercent = (bestPrice, givenPrice, DeliveryCost) => {
    return (
      ((parseFloat(givenPrice) -
        parseFloat(bestPrice) -
        parseFloat(DeliveryCost)) /
        parseFloat(bestPrice)) *
      100
    ).toFixed(2);
  };

  const computePercentToPrice = (bestPrice, percentage, DeliveryCost) => {
    // alert("bestPrice=" + bestPrice + "percentage= " + percentage + "DeliveryCost=" + DeliveryCost)
    const price = (
      (parseFloat(bestPrice) * parseFloat(percentage)) / 100 +
      parseFloat(bestPrice) +
      parseFloat(DeliveryCost)
    ).toFixed(2);
    return price;
  };

  const handlePriceValueChanges = (query_id, field, value) => {
    setPriceValues((prevPriceValues) => {
      const updatedPriceValues = {
        ...prevPriceValues,
        [query_id]: {
          ...prevPriceValues[query_id],
          [field]: value,
        },
      };
      return updatedPriceValues;
    });
  };

  //best price received change handler
  const handleBestPriceChange = (query_id, best_price_received) => {
    handlePriceValueChanges(query_id, "best_price_received", best_price_received);

    if (best_price_received) {
      var cost_data = (best_price_received != 0
        ? costPriceSet.find((item) => best_price_received >= item.MinPrice && best_price_received <= item.MaxPrice)
        : 0) || { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 };

      handlePriceValueChanges(query_id, "price_percentage", cost_data.GP_Percentage);
      handlePriceValueChanges(query_id, "delivery_cost", cost_data);

      handlePriceValueChanges(query_id, "price_given", 0);

      const price_percentage = cost_data.GP_Percentage;

      if (price_percentage != 0) {
        var price = computePercentToPrice(best_price_received, price_percentage, cost_data.DeliveryCost);
        handlePriceValueChanges(query_id, "price_given", price);
      }
    } else {
      handlePriceValueChanges(query_id, "price_percentage", 0);
      handlePriceValueChanges(query_id, "price_given", 0);
      handlePriceValueChanges(query_id, "delivery_cost", {
        DeliveryCost: 0,
        GP_Percentage: 0,
        MinPrice: 0,
        MaxPrice: 0,
      });
    }
  };

  //percentage number change handler
  const handlePercentageChange = (query_id, percent) => {
    const best_price = priceValues[query_id]["best_price_received"] ?? 0;
    if (best_price == 0) {
      alert("Please enter the Best Price received for the item first.");
    } else {
      handlePriceValueChanges(query_id, "price_percentage", percent);

      const deliveryCost = priceValues[query_id]["delivery_cost"]["DeliveryCost"];

      if (percent != 0) {
        var price = computePercentToPrice(best_price, percent, deliveryCost);
        handlePriceValueChanges(query_id, "price_given", price);
      } else {
        handlePriceValueChanges(query_id, "price_given", best_price + deliveryCost);
      }
    }
  };

  //given price change handler
  const handleGivenPriceChange = (query_id, given_price) => {
    const best_price = priceValues[query_id]["best_price_received"] ?? 0;

    if (best_price == 0) {
      alert("Please enter the Best Price received for the item first.");
    } else if (given_price == 0) {
      handlePriceValueChanges(query_id, "price_given", given_price);
      handlePriceValueChanges(query_id, "price_percentage", 0);
      handlePriceValueChanges(query_id, "delivery_cost", {
        DeliveryCost: 0,
        GP_Percentage: 0,
        MinPrice: 0,
        MaxPrice: 0,
      });
    } else {
      handlePriceValueChanges(query_id, "price_given", given_price);
      const deliveryCost =
        priceValues[query_id]["delivery_cost"]["DeliveryCost"];

      const percentage = computePriceToPercent(best_price, given_price, deliveryCost);
      handlePriceValueChanges(query_id, "price_percentage", percentage);
    }
  };

  //given price change handler
  const handleDeliverycostChange = (query_id, delivery_cost) => {
    const best_price = priceValues[query_id]["best_price_received"] ?? 0;

    if (best_price == 0) {
      alert("Please enter the Best Price received for the item first.");
    } else {
      if (delivery_cost) {
        handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: delivery_cost, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0, });

        const percentage = priceValues[query_id]["price_percentage"];

        const given_price = computePercentToPrice(best_price, percentage, delivery_cost);
        handlePriceValueChanges(query_id, "price_given", given_price);
      } else {
        handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: delivery_cost, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0, });

        const percentage = priceValues[query_id]["price_percentage"];

        const given_price = computePercentToPrice(best_price, percentage, 0);
        handlePriceValueChanges(query_id, "price_given", given_price);
      }
    }
  };

  //update to DB upon any price change
  const handlePriceUpdate = (query_id) => {
    const status = priceValues[query_id]["status"];

    const best_price_received = priceValues[query_id]["best_price_received"];
    const price_given = priceValues[query_id]["price_given"];
    const deliveryCost = priceValues[query_id]["delivery_cost"]["DeliveryCost"];
    if (best_price_received != 0 && price_given != 0) {
      if (status == "Waiting for Price") {
        handleQueryUpdate("", false, "Waiting for confirmation", true, price_given, true, best_price_received, true, query_id, deliveryCost, true, "", false, false);
        handlePriceValueChanges(query_id, "status", "Waiting for confirmation");
        SuccessSnackbar("Query status also has been updated for your convenience!");
      } else {
        handleQueryUpdate("", false, "", false, price_given, true, best_price_received, true, query_id, deliveryCost, true, "", false, false);
      }
    }
  };

  //update to DB upon any remarks change
  const handleRemarksUpdate = (query_id) => {

    const remark = priceValues[query_id]["remarks"];

    if (remark) {
      handleQueryUpdate("", false, "", false, "", false, "", false, query_id, "", false, "", false, false, remark, true);
      SuccessSnackbar("Remarks updated successfully!");
    }
  };
  const handle_f_date_Update = (query_id) => {

    const f_date = priceValues[query_id]["f_date"];

    if (f_date) {
      handleQueryUpdate("", false, "", false, "", false, "", false, query_id, "", false, "", false, false, "", true, f_date, true);
      SuccessSnackbar("Remarks updated successfully!");
    }
  };
  const handleWarrantyUpdate = (query_id, value) => {

    if (value) {
      handleQueryUpdate("", false, "", false, "", false, "", false, query_id, "", false, "", false, false, "", true, "", false, value, true);
      SuccessSnackbar("Warranty Info updated successfully!");
    }
  };

  // socket.on('QueryDataUpdated', (values) => {
  //     console.log("QueryDataUpdated = " + JSON.stringify(values));
  //     // var data = values.data;
  //     // SuccessSnackbar(values.message, '(ID : ' + data[0].query_id + ")");
  //     // handling_updated_query(data);
  //     SuccessSnackbar("New data available", "Load new data", () => handling_updated_query(values.data), 30000);
  // })
  // socket.on('newQueryLoaded', (values) => {
  //     // var data = values.data;
  //     // SuccessSnackbar(values.message);
  //     // handling_added_query(data);
  //     SuccessSnackbar("New data available", "Load new data",() =>  handling_added_query(values.data), 30000);
  // })
  const getStatusColor = (status) => {
    const statusObj = queryStatusSet.find((item) => item.name === status);
    return statusObj ? statusObj.colour || "gray" : "gray";
  };

  const handleRemarksChange = (query_id, value) => {
    setPriceValues((prevData) => ({
      ...prevData,
      [query_id]: {
        ...prevData[query_id],
        remarks: value,
      },
    }));
  }

  const handle_f_date_Change = (query_id, value) => {
    setPriceValues((prevData) => ({
      ...prevData,
      [query_id]: {
        ...prevData[query_id],
        f_date: value,
      },
    }));
  }

  const handleWarrantyChange = (query_id, value) => {
    setPriceValues((prevData) => ({
      ...prevData,
      [query_id]: {
        ...prevData[query_id],
        warranty: value,
      },
    }));
  }

  const get_remarks = () => {
    axios.get(API_DOMAIN + "/whatsapp/get_remarks", head).then((res) => {
      if (res.data.success) {
        setRemarksSet(res.data.data);
      }
    });
  }

  const get_parts = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_parts_info", head).then((res) => {
      if (res.data.success) {
        setPartsDataSet(res.data.data);
      }
    });
  }

  const get_query_origins = async () => {
    const result = await axios.post(API_DOMAIN + "/whatsapp/get_misc_mode", { mode: "query_source" });
    if (result.data.success) {
      setqueryOriginSet(result.data.data);
    }
  }

  const getCarLogotypes = async () => {
    try {
      const response = await axios.get(
        `https://cdn.jsdelivr.net/gh/avto-dev/vehicle-logotypes@2.x/src/vehicle-logotypes.json`
      );
      setCarLogoTypes(response.data)
      console.log(JSON.stringify(response.data));
    } catch (error) {
      ErrorSnackbar("Error fetching logotypes:", error);
    }
  };

  const get_postponed_queries = async () => {
    var result = await axios.get(`${API_DOMAIN}/whatsapp/get_postponed_queries`);
    if (result.data.success) {
      const formatted_data = result?.data?.data?.map((element) => {
        // Construct the status string without unnecessary null values
        const remarks = element?.moredetails?.remarks?.trim();
        return {
          ...element,
          status: `${element?.postponed_to}\n\n(${element?.status})${remarks ? `\n\n(${remarks})` : ''}`
        };
      });

      setPostponedQuerySet(formatted_data);

    }
    else {
      ErrorSnackbar(`Error retrieving postponed queries`);
    }
  }

  const reload_query_succ_responses = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_query_succ_status", { api_secret: "uzdpart" }, head)
      .then((res) => {
        if (res.data.success) {
          setQueryStatusSet(res.data.data.map(element => ({ value: element.id, label: element.name, colour: element.colour })));
        }
      })
      .catch((err) => {
        ErrorSnackbar(err.message);
      });
  }

  useEffect(() => {
    getCarLogotypes(); // Call the function inside useEffect
  }, []);

  useEffect(() => {
    const head = {
      headers: {
        accessToken: localStorage.getItem("accessToken")
      }
    };
    get_query_origins();
    load_users();
    get_postponed_queries();
    get_remarks();
    get_parts();
    reload_querysets(head);
    //this is the tags
    reload_makes(head);
    //brand names, models will load when a brand is selected
    reload_brands(head);
    //now it loads the supplierv2 data
    reload_suppliers(head);
    reload_customers(head);
    reload_region(head);
    reload_cost_prices(head);
    const waStatusCheck = async (head) => {
      axios.post(API_DOMAIN + "/whatsapp/status", { api_secret: "uzdpart" }, head).then((res) => {
        if (res.data.success) {
          setWhatsappStatus(res.data.data.status);
          switch (res.data.data.status.status) {
            case eventStatusMap.logged_in:
              document.getElementById("wa_status_img_small").src = green_tick;
              break;
            default:
              document.getElementById("wa_status_img_small").src = yellow_error;
              setTimeout(() => {
                waStatusCheck();
              }, 3000);
              break;
          }
        } else {
          document.getElementById("wa_status_img_small").src = yellow_error;
        }
      })
        .catch((err) => {
          ErrorSnackbar(err.message);
        });
    };
    reload_query_succ_responses();
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          alert(
            "Please allow to show notifications so that You'll know when the query got resolved."
          );
          console.log("Notification permission denied.");
        }
      });
    }
    waStatusCheck();

    const handleWindowFocus = () => {
      setIsTabFocused(true);
    };

    const handleWindowBlur = () => {
      setIsTabFocused(false);
      queryDateRef?.current?.setOpen(false);
    };

    window.addEventListener("focus", handleWindowFocus);
    window.addEventListener("blur", handleWindowBlur);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("blur", handleWindowBlur);
      socket.off("QueryDataUpdated");
      socket.off("newQueryLoaded");
    };
  }, []);
  useEffect(() => {
    isTabFocusedRef.current = isTabFocused;
  }, [isTabFocused]);
  useEffect(() => {
    handleMakeChange(make_data);
  }, [make_data]);

  const [priceValues, setPriceValues] = useState({});
  const [queryDataMap, setQueryDataMap] = useState({});

  const reloadQuerySet = () => {
    setIsDownloadButtonDisabled(true);
    setLoading(true);

    // Check if the user should be prompted to confirm
    if (!startDate && !endDate) {
      const confirmFetch = window.confirm("No dates selected, Search will scan the whole database, may take longer this way.");
      // If the user declines, skip the data fetch and reset the states
      if (!confirmFetch) {
        setIsDownloadButtonDisabled(false);
        setLoading(false);
        return;
      }
    }

    axios.post(API_DOMAIN + "/whatsapp/get_querysets", { search: searchTerm, from: dayjs(startDate).format("YYYY-MM-DD"), to: dayjs(endDate).format("YYYY-MM-DD"), api_secret: "uzdpart", id: authState.id, user: searchUser }, head)
      .then((res) => {
        setIsDownloadButtonDisabled(false);
        if (res.data.success) {
          handling_setquerySet(res.data.data);
          setLoading(false);
          if (
            searchTerm ||
            dayjs(startDate).isValid() ||
            dayjs(endDate).isValid()
          ) {
            SuccessSnackbar("Fetched " + res.data.data.length + " search results");
          }
        }
      })
      .catch((error) => {
        ErrorSnackbar("Error fetching data, Please try again later. " + error.message);
        setLoading(false);
      });
  };


  const send_whatsapp = (query_id, behavior) => {
    //   var alert_message = "";
    //   if (behavior === 1)
    //     alert_message = `Query ${query_id} was sent already, Send again?`
    //   if (behavior === 2)
    //     alert_message = `Please check the integrity of the query data, Ready to send query now?`

    //   if (window.confirm(alert_message))
    //     InfoSnackbar(`sending Query ${query_id} again...`)
  }

  const openNewTab = (url) => {
    if (!url) {
      console.error("You forgot the URL, you idiot!");
      return;
    }
    window.open(url, '_blank').focus();
  }

  const open_wati_chat = async (id, phone) => {
    // document.getElementById(id).disabled = true;
    InfoSnackbar(`Switching to WATI chat, please wait...`)
    var result = await axios.post(`${API_DOMAIN}/whatsapp/get_chat_id_from_number`, { number: phone });
    if (result.data.success) {
      if (result.data.data.length == 0) {
        ErrorSnackbar("Something went wrong, Try again.");
      }
      else {
        const chat_id = result.data.data[0].ticket.conversationId;
        const wati_url = `https://live.wati.io/5068/teamInbox/${chat_id}?filter={%22filterType%22:0,%22channelType%22:0,%22filterChannelIds%22:[%22%22]}`
        openNewTab(wati_url);
      }
    }
    else {
      ErrorSnackbar("Failed to fetch chat info from WATI.");
    }
    // document.getElementById(id).disabled = false;
  }

  useEffect(() => {
    const handleDataChange = async () => {
      if (Object.keys(currentQueryData).length !== 0 && triggerAction !== null) {
        await handleWatiMessages(currentQueryData, false); // Ensure completion
        switch (triggerAction) {
          case "1":
            if (priceValues[currentQueryData.query_id]["price_given"] && priceValues[currentQueryData.query_id]["price_given"] != 0 && priceValues[currentQueryData.query_id]["warranty"] && priceValues[currentQueryData.query_id]["warranty"] != 0) {
              handleOpenQuickRepliesEdit("66a88464ce5c8cc0f7931371");
            }
            else
              alert("Please check entered price details and/or warranty info.")
            break;
          case "2":
            handleOpenQuickRepliesEdit("6755a194c5777fabc9b6e791");
            break;
          case "3":
            handleOpenQuickRepliesEdit("6755a194c5777fabc9b6e791");
            break;
          case "4":
            handleOpenLinkGen(currentQueryData);
            break;
          default:
            alert("Undefined Action!");
            break;
        }

        // Reset triggerAction after completion
        setTriggerAction(null);
      }
    };

    handleDataChange();
  }, [currentQueryData, triggerAction]);

  const handleRowsDelete = (query_id) => {
    if (window.confirm(`Do you want to delete Query with ID: ${query_id}?`)) {
      handleQueryUpdate("", "", "", "", "", "", "", "", query_id, "", "", "", false, true);
    };
    const newData = querySet.filter((query) => query.query_id != query_id);
    setquerySet(newData);
    setTimeout(() => {
      filter_queryset_tabchange(currentTabState, newData);
    }, 0);
  }
  const handleScheduleWatiMessages = (query_id) => {
    if (window.confirm("Are you sure you want to schedule WATI reminder messages for the query ID: " + query_id + "?")) {
      InfoSnackbar("Scheduling WATI reminder messages for the query ID: " + query_id);
      axios.post(API_DOMAIN + "/whatsapp/schedule_wati_reminders", { query_id }, head).then((res) => {
        if (res.data.success) {
          SuccessSnackbar("WATI reminder messages scheduled successfully for the query ID: " + query_id);
          handleQueryValueChange(query_id, "scheduled", true);
        }
      })
        .catch((error) => {
          ErrorSnackbar(error.message);
        });
    }
    else {
      InfoSnackbar("Scheduling WATI reminder messages for the query ID: " + query_id + " cancelled");
    }
  }

  const save_settings = (settings) => {
    axios.post(API_DOMAIN + "/whatsapp/add_query_succ_status", { statuses: settings, }, head).then((res) => {
      if (res.data.success) { reload_query_succ_responses(); handleCloseSettingsModal(); SuccessSnackbar("Saved settings successfully") } else { ErrorSnackbar(res.data.message) }
    });
  };

  const [templates, setTemplates] = useState([]);
  const [templateTemp, setTemplatesTemp] = useState([]);
  const handleWatiMessages = async (data, openWindow = true) => {
    try {
      const res = await axios.post(API_DOMAIN + "/whatsapp/get_wati_templates", { template: false }, head);

      if (res.data.success) {
        setTemplates(res.data.data);
        setTemplatesTemp(res.data.data);
        setTimeout(() => {
          if (openWindow)
            handleOpenQuickReplies(data);
        }, 0);
      } else {
        ErrorSnackbar("Something went wrong while fetching the templates");
      }

    } catch (error) {
      ErrorSnackbar(error.message);
    }
  };

  const rangePresets = [
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
    {
      label: 'Last 60 Days',
      value: [dayjs().add(-60, 'd'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Last 7 Days',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Today',
      value: [dayjs(), dayjs()],
    }
  ];

  function getCountryCode(phoneNumber) {
    phoneNumber = phoneNumber.replaceAll("+", "");
    phoneNumber = phoneNumber[0] == '0' ? phoneNumber.replace("0", "971") : phoneNumber;

    // Add a "+" sign to the phone number to make it a valid international format
    const internationalPhoneNumber = `+${phoneNumber}`;

    // Parse the phone number
    const parsedNumber = parsePhoneNumber(internationalPhoneNumber);

    // Check if the number is valid
    if (!parsedNumber) {
      // alert(internationalPhoneNumber)
      throw new Error('Invalid phone number');
    }

    // Return the country code (e.g., "IN" for India)
    // return parsedNumber.country == 'AE' ? 'ARE' : parsedNumber.country;
    return parsedNumber.country;
  }

  async function copyToClipboardModern(text, term) {
    try {
      await navigator.clipboard.writeText(text);
      SuccessSnackbar(term + " copied to clipboard!");
    } catch (err) {
      ErrorSnackbar(`Failed to copy : ${term}`, err);
    }
  }

  const columns = [
    {
      key: "query_id",
      title: "Query ID",
      render: (value) => (
        <React.Fragment>
          <div className="flex justify-between">
            <Tooltip className="cursor-pointer" title="Click here to copy the Query ID to clipboard." onClick={() => copyToClipboardModern(value.query_id, "Query ID")}>
              {value.query_id}
            </Tooltip>

            {(() => {
              var parsedData = value?.moredetails?.success || '[]'

              try { parsedData = JSON.parse(parsedData); } catch (error) { }

              return value?.moredetails?.success?.length > 0 ? (
                <div>
                  <Tooltip title="Already sent. Click to send again.">
                    <img style={{ width: "20px", cursor: "pointer" }} src={whatsapp_logo} alt="Whatsapp_logo" />
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <Tooltip title="Not sent. Click to send Now.">
                    <img style={{ width: "20px", filter: "grayscale(1)", cursor: "pointer" }} src={whatsapp_logo} alt="Whatsapp_logo" />
                  </Tooltip>
                </div>
              )

            })()}
          </div>
        </React.Fragment>
      ),
      sorter: (a, b) => a.query_id - b.query_id,
      sortDirections: ["descend", "ascend"],
      width: "7%",
      fixed: "left"
      //   responsive: ["xs"]
    },
    {
      key: "timeline",
      title: "Date",
      dataIndex: "timeline",
      render: (value) => (
        <React.Fragment>
          {value && value.length > 0 && value[0].query_created
            ? dayjs(value[0].query_created).format("DD-MM-YY hh:mmA").toString().replace(" ", "\n")
            : "Timeline data not available"}
        </React.Fragment>
      ),
      sorter: (a, b) => {
        const getDate = (item) =>
          item.timeline &&
            item.timeline.length > 0 &&
            item.timeline[0].query_created
            ? dayjs(item.timeline[0].query_created, "YYYY-MM-DD hh:mm:ss A").valueOf()
            : 0; // Default to 0 if date doesn't exist

        return getDate(a) - getDate(b);
      },
      width: "9%",
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "user",
      title: "User",
      dataIndex: "user",
      sorter: (a, b) => a.user.localeCompare(b.user),
      sortDirections: ["descend", "ascend"],
      width: "10%"
    },
    {
      key: "make_model_additional_info",
      title: "Make - Model",
      render: (record) => (
        <React.Fragment>
          <div className="flex drop-shadow">
            <div style={{ width: "50px" }}>
              {carLogoTypes[record.make.toLowerCase().replaceAll(" ", "-")] ?
                (<Tooltip title={record.make}>
                  <img style={{ width: "100%" }} src={carLogoTypes[record.make.toLowerCase().replaceAll(" ", "-")]?.logotype?.uri ? carLogoTypes[record.make.toLowerCase().replaceAll(" ", "-")].logotype.uri : alert(record.make)} />
                </Tooltip>) : record.make}
            </div>

            <div style={{ width: "100%" }}>
              <div className="border rounded p-0.5 flex" style={{ backgroundColor: "#9aede9", color: "#295754", width: "100%", overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100%", minWidth: "70px" }}>
                <div style={{ fontWeight: "600", color: "Black" }}>{record.model}&nbsp;</div>
                {record.year ? (<div>({record.year})&nbsp;</div>) : ""}
                {record.cylinder ? (<div>({record.cylinder} Cylinders)&nbsp;</div>) : ""}
                {record.region ? (<div>({record.region})&nbsp;</div>) : ""}
              </div>
              {/* {record.chassis_no ? (<div className="border rounded p-0.5 mt-0.5" style={{ backgroundColor: "#b0ebe8", color: "#295754" }}>{record.chassis_no}</div>) : ""}
              {record.model_details ?
                (<div className="flex align-middle">
                  <div className="border rounded p-0.5 mt-0.5" style={{ backgroundColor: "#c3e8e6", color: "#295754", width: "100%" }}>{record.model_details}</div>
                </div>
                ) : ""} */}
            </div>
          </div>
        </React.Fragment >
      ),
      width: "20%"
    },
    {
      key: "body_part",
      title: "Parts",
      render: (record) => (
        <React.Fragment>
          <div style={{ maxHeight: "50px", overflowY: "auto", scrollSnapType: "y mandatory", scrollBehavior: "smooth" }}>
            {record.body_part.split(",").map((item, index) => (<div style={{ scrollSnapAlign: "start" }}>{index + 1}.&nbsp;{item}</div>))}
          </div>
          {record.body_part.split(",").length > 1 ? (<div style={{ fontSize: "11px", color: "#777" }} className="flex justify-end pr-1"><Tooltip title="Scroll inside the parts-list to see all the names.">Total :&nbsp;{record.body_part.split(",").length}</Tooltip></div>) : null}
        </React.Fragment>
      ),
      width: "15%"
    },
    {
      key: "cus_name",
      title: "Customer",
      render: (record) => (
        <React.Fragment>
          {record.moredetails?.queryOrigin?.length > 0 ? (<div style={{ fontSize: "11px", color: "#777" }} className="flex justify-end pr-1">{record.moredetails?.queryOrigin[0]?.label}</div>) : ""}
          <div className="border rounded p-1" style={{ fontWeight: "550", overflowX: "auto", whiteSpace: "nowrap", maxWidth: "180px", minWidth: "90px" }}>
            {record?.cus_name ? record?.cus_name : <i>Name Unknown</i>}
          </div>
          <div className="border rounded p-1 mt-0.5 flex justify-between" style={{ maxWidth: "180px", minWidth: "90px" }}>
            <div className="flex">
              <Tooltip title={country_codes[getCountryCode(record?.phone)] || "Unknown"}>
                <img style={{ width: "15px" }} src={"https://cdn.clare.ai/flags/" + getCountryCode(record?.phone) + ".svg"} alt="Unknown" />
              </Tooltip>
              &nbsp;
              <div>{record?.phone ? (<div className="cursor-pointer" onClick={() => copyToClipboardModern(record.phone, "Number")}><Tooltip title="Click here to copy the number to clipboard.">{record?.phone}</Tooltip></div>) : <i>Number Unknown</i>}</div>
            </div>
            <div className="flex items-center justify-center cursor-pointer">
              <Tooltip title="Go to the WATI chat">
                <img id={`wati_chat_button_${record?.query_id}`} onClick={() => open_wati_chat(`wati_chat_button_${record?.query_id}`, record?.phone)} style={{ width: "15px" }} src={wati_logo_only} />
              </Tooltip>
            </div>
          </div>
        </React.Fragment >
      ),
      sorter: (a, b) => a?.moredetails?.queryOrigin[0]?.label.localeCompare(b?.moredetails?.queryOrigin[0]?.label),
      sortDirections: ["descend", "ascend"],
      width: "12%"
    },
    {
      key: "status",
      title: "Current Status",
      render: (record) => (
        <React.Fragment>
          {record?.status}
        </React.Fragment >
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: "13%"
    },
    {
      key: "price_given",
      title: "SP",
      dataIndex: "price_given",
      sorter: (a, b) => a.price_given - b.price_given,
      sortDirections: ["descend", "ascend"],
      width: "6%",
    },
    {
      key: "moredetails",
      title: "Actions",
      render: (record) => (
        <React.Fragment>
          <div className="flex justify-center">
            <div className="gap-2" style={{ fontSize: "18px", width: "20px" }}>

              <Tooltip placement="left" title="Edit and save or save & send this query.">
                <EditOutlined className="cursor-pointer hover:scale-150 transition-transform duration-300 ease-in-out" />
              </Tooltip>

              <Tooltip placement="left" title="Duplicate this query and make a new one.">
                <RetweetOutlined className="cursor-pointer hover:scale-150 transition-transform duration-300 ease-in-out" onClick={() => handleQueryDuplicate(record)} />
              </Tooltip>

              <Tooltip placement="left" title="Delete this query">
                <DeleteOutlined className="cursor-pointer hover:scale-150 transition-transform duration-300 ease-in-out" onClick={() => handleRowsDelete(record.query_id)} />
              </Tooltip>

            </div>
          </div>
        </React.Fragment>
      ),
      width: "4%"
    },
  ];

  const tabItems = [
    {
      key: 'All',
      label: 'All',
      icon: <Badge overflowCount={9999999999} showZero count={querySet?.length} color="blue" status="progress" />
    },
    {
      key: 'Waiting for Price',
      label: 'Waiting for Price',
      icon: <Badge overflowCount={9999999999} showZero count={querySet?.filter(element => element?.status == 'Waiting for Price')?.length} color="grey" status="default" />
    },
    {
      key: 'Waiting for confirmation',
      label: 'Waiting for confirmation',
      icon: <Badge overflowCount={9999999999} showZero count={querySet?.filter(element => element?.status == 'Waiting for confirmation')?.length} color="yellow" status="warning" />
    },
    {
      key: 'Confirmed,Confirmed & Paid',
      label: 'Confirmed',
      icon: <Badge overflowCount={9999999999} showZero count={querySet?.filter(element => element?.status == 'Confirmed' || element?.status == 'Confirmed & Paid')?.length || 0} color="green" status="success" />
    },
    {
      key: 'Cancelled,Customer Declined,Unavailable,Query Lost,Other',
      label: 'Lost',
      icon: <Badge overflowCount={9999999999} showZero count={querySet?.filter(element => element?.status == 'Cancelled' || element?.status == 'Customer Declined' || element?.status == 'Unavailable' || element?.status == 'Query Lost' || element?.status == 'Other')?.length || 0} color="red" status="error" />
    },
    {
      key: 'Postponed',
      label: 'Postponed',
      icon: <Badge style={{ backgroundColor: "#ff8205" }} overflowCount={9999999999} showZero count={querySet?.filter(element => element?.status == 'Postponed')?.length || 0} color="red" status="error" />
    },
    {
      key: 'Postponed_to_today',
      label: 'Postponed to Today',
      icon: <Badge style={{ backgroundColor: "#bf6000" }} overflowCount={9999999999} showZero count={postponedQuerySet?.filter((element) => element?.status?.includes("Today")).length || 0} color="red" status="error" />
    },
  ];

  const onTabChange = (e) => {
    setCurrentTabState(e);
    setLoading(true);
    filter_queryset_tabchange(e);
  }
  const filter_queryset_tabchange = (e, updatedQuerySet = querySet) => {
    const items = e.split(",");
    if (items.includes("All")) {
      setquerySetTable(updatedQuerySet);
      setLoading(false);
    } else if (items.includes("Postponed_to_today")) {
      setquerySetTable(postponedQuerySet); // Assuming postponedQuerySet is already filtered correctly
      setLoading(false);
    } else {
      setquerySetTable(updatedQuerySet.filter(element => items.includes(element.status)));
      setLoading(false);
    }
    console.log("yohoo = " + JSON.stringify(querySetTable));
  };
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  const hover_menu_items = [
    {
      key: '1',
      label: 'Qoute',
    },
    {
      key: '2',
      label: 'Follow-up',
    },
    {
      key: '4',
      label: 'Pay-By-Link',
    },
  ];
  const generateAccordionItems = (record) => {
    return [
      {
        key: '1',
        label: 'Query Reply Timeline',
        children: <div className="border rounded p-1 overflow-y-auto" style={{ height: "150px", overflowY: "auto", width: "100%" }}>
          {record?.timeline ? (
            <><div className="flex justify-end mb-2" style={{ position: "sticky", top: -6, backgroundColor: "white", zIndex: 10 }}>
              <div className="pl-2 pr-2" style={{ fontSize: "10px" }}>
                {record?.timeline ? "Time Spent : " + calculateTimeDifference(record?.timeline) : ""}
              </div>
            </div>
              <Timeline
                className="pl-2"
                mode="left"
                items={formatTimeline(record?.timeline).reverse()}
              /></>) : "No Timeline Data Available!"}

        </div>,
      },
      {
        key: '2',
        label: 'Bot Information',
        children:
          <div className="border rounded p-1 overflow-y-auto" style={{ height: "150px", overflowY: "auto", width: "100%" }}>
            <div
              className="flex justify-between mb-2"
              style={{ position: "sticky", top: -6, backgroundColor: "white", zIndex: 10, fontSize: "10px" }}
            >
              <div style={{ width: "260px", overflowY: "auto" }}>Sup Grps :&nbsp;<b>{(() => {
                var parsedData = record?.sup_set || '[]'
                try { parsedData = JSON.parse(parsedData); } catch (error) { }

                if (!parsedData.length) return 'Unknown';

                return parsedData.map((e) => {
                  return (e.label);
                }).join(", ")
              })()}</b>
              </div>
              <div className="pr-2" style={{ fontSize: "10px" }}>
                Total Suppliers :&nbsp;
                <b>
                  {(() => {
                    var parsedData = record?.moredetails?.success || '[]'
                    try { parsedData = JSON.parse(parsedData); } catch (error) { }

                    if (parsedData?.length)
                      return (parsedData?.length)
                    else
                      return (`No data available`)
                  })()}
                </b>
              </div>
            </div>
            <div className="pl-2">
              {(() => {
                var parsedData = record?.moredetails?.success || '[]'
                try { parsedData = JSON.parse(parsedData); } catch (error) { }

                if (!parsedData.length) return null;

                const sortedData = parsedData.sort((a, b) => {
                  const isUzdPartA = supplierSet.find(f => f.number === a.phoneNumber.replace("@c.us", ""))?.name?.toLowerCase().includes("uzdpart") ? 1 : 0;
                  const isUzdPartB = supplierSet.find(f => f.number === b.phoneNumber.replace("@c.us", ""))?.name?.toLowerCase().includes("uzdpart") ? 1 : 0;

                  return isUzdPartA - isUzdPartB;  // Move uzdpart entries to the end
                });

                return sortedData.map((e, index) => {
                  const supplierName =
                    supplierSet.find(f => f.number === e.phoneNumber.replace("@c.us", ""))?.name || "Unknown Supplier, Probably removed";

                  return (
                    <div key={index}>
                      {supplierName?.toLowerCase().includes("uzdpart") ? (
                        <i style={{ color: "gray", fontWeight: "400" }}>
                          {index + 1}.&nbsp;
                          <Tooltip className="cursor-pointer" title="Click here to copy the Supplier Number to clipboard." onClick={() => copyToClipboardModern(e.phoneNumber.replace("@c.us", ""), "Supplier Phone number")}>
                            <u>{e.phoneNumber.replace("@c.us", "")}</u>
                          </Tooltip>&nbsp;({supplierName})
                        </i>
                      ) : (
                        <div>
                          {index + 1}.&nbsp;
                          <Tooltip className="cursor-pointer" title="Click here to copy the Supplier Number to clipboard." onClick={() => copyToClipboardModern(e.phoneNumber.replace("@c.us", ""), "Supplier Phone number")}>
                            <u>{e.phoneNumber.replace("@c.us", "")}</u>
                          </Tooltip>&nbsp;({supplierName})
                        </div>
                      )}
                    </div>
                  );
                });
              })()}
            </div>

          </div>
      }
    ]
  }
  const onMenuClick = (e, data) => {
    setCurrentQueryData(data);  // State update triggers useEffect
    setTriggerAction(e.key);    // Triggers useEffect when state changes
  };
  const calculateTimeDifference = (timeline) => {
    if (timeline.length < 2) return 'Not enough data';

    const firstTime = new Date(Object.values(timeline[0])[0]);
    const lastTime = new Date(Object.values(timeline[timeline.length - 1])[0]);

    const diffInSeconds = Math.abs((lastTime - firstTime) / 1000);
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = Math.floor(diffInSeconds % 60);

    return `${minutes} minutes ${seconds} seconds`;
  }
  const formatTimeline = (timeline) => {
    return timeline.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return {
        children: `${key.replace(/_/g, ' ')} ${value}`
      };
    });
  }
  const flashRowWithPagination = (rowKey, color = "#ffd700", flashDuration = 1000) => {
    // Access the Ant Design table element
    const tableElement = document.querySelector(".ant-table");

    if (!tableElement) {
      console.warn("Table not found!");
      return;
    }

    // Step 1: Check if the row is already in the current page
    let rowElement = document.querySelector(`.ant-table-row[data-row-key="${rowKey}"]`);

    if (rowElement) {
      // If row is found, flash it
      flashRow(rowKey, color, flashDuration);
      return;
    }

    // Step 2: If row is not found, we assume it's on another page.
    // Find the pagination buttons
    const pagination = document.querySelector(".ant-pagination");
    if (!pagination) {
      console.warn("Pagination not found!");
      return;
    }

    const totalPages = document.querySelectorAll(".ant-pagination-item").length;

    let currentPage = 1;

    const goToNextPage = () => {
      const nextPageButton = document.querySelector(".ant-pagination-next");
      if (nextPageButton && !nextPageButton.classList.contains("ant-pagination-disabled")) {
        nextPageButton.click();
        setTimeout(checkForRow, 300);  // Allow time for the page to load
      } else {
        console.warn(`Row with key "${rowKey}" not found on any page.`);
      }
    };

    const checkForRow = () => {
      rowElement = document.querySelector(`.ant-table-row[data-row-key="${rowKey}"]`);
      if (rowElement) {
        // Row found, flash it
        flashRow(rowKey, color, flashDuration);
      } else if (currentPage < totalPages) {
        currentPage++;
        goToNextPage();
      } else {
        console.warn(`Row with key "${rowKey}" not found!`);
      }
    };

    // Start checking pages
    checkForRow();
  };

  // Helper function to flash row (already created)
  const flashRow = (rowKey, color, flashDuration) => {
    const rowElement = document.querySelector(`.ant-table-row[data-row-key="${rowKey}"]`);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: "smooth", block: "center" });

      const originalColor = rowElement.style.backgroundColor;
      const animationDuration = flashDuration / 8;
      rowElement.style.transition = `background-color ${animationDuration}ms ease-in-out`;

      let flashCount = 0;
      const flashEffect = setInterval(() => {
        if (flashCount >= 6) {
          clearInterval(flashEffect);
          rowElement.style.backgroundColor = originalColor;
          rowElement.style.transition = "";
        } else {
          rowElement.style.backgroundColor = flashCount % 2 === 0 ? color : originalColor;
          flashCount++;
        }
      }, flashDuration / 3);
    }
  };


  return (
    <>
      <div className="flex justify-between drop-shadow" style={{ width: "100%", backgroundColor: "#64aafa", border: "1px solid #64aafa", borderRadius: "5px", overflowY: "auto" }}>
        <div className="flex p-2.5 flex-wrap gap-1.5" style={{ minWidth: "80%" }}>

          <AntdInput
            style={{ width: "225px" }}
            allowClear
            placeholder="Search Term"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(event) => { if (event.key === 'Enter') { reloadQuerySet(); } }}
          />

          <RangePicker style={{ width: "225px" }} presets={rangePresets} value={[startDate, endDate]} onChange={onSearchDateChange} maxDate={dayjs()} />

          {authState.admin ?
            (<Select
              style={{ maxWidth: "225px" }}
              allowClear
              showSearch
              optionFilterProp="label"
              id="search_select"

              onChange={(e) => setSearchUser(e)}
              placeholder="Select User"
              options={usersSet}
            />) : ""}

          <Button style={{ width: "60px" }} type="primary" onClick={reloadQuerySet}>
            <SearchOutlined />
          </Button>

          <Tooltip title="Download Current search result viewing tab data as a PDF File">
            <Button id="pdf_button" disabled={isDownloadButtonDisabled} type="primary" onClick={() => handlePdfClick()}>
              <FilePdfOutlined /><DownloadOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Download Current search result viewing tab data as an Excel File">
            <Button id="excel_button" disabled={isDownloadButtonDisabled} type="primary" onClick={() => handleExcelClick()}>
              <FileExcelOutlined /><DownloadOutlined />
            </Button>
          </Tooltip>

        </div>
        <div className="flex justify-end p-2.5 gap-5 flex-wrap" style={{ width: "20%" }}>
          <div className="flex flex-wrap gap-1.5">
            <Tooltip title="Add new Query">
              <Button style={{ width: "60px" }} id="excel_button" disabled={isDownloadButtonDisabled} type="primary" onClick={handleOpen}>
                <PlusSquareOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Open settings">
              <Button style={{ width: "60px" }} id="excel_button" disabled={isDownloadButtonDisabled} type="primary" onClick={handleOpenSettingsModal}>
                <SettingOutlined />
              </Button>
            </Tooltip>

          </div>
        </div>
      </div>

      <br />
      <div>
        <div className="flex justify-center">
          <Tabs id="main_tabs" style={{ width: "98%", marginBottom: "-16px" }} defaultActiveKey="1" items={tabItems} onChange={onTabChange} animated />
        </div>
        <Table
          rowKey={(record) => record.query_id}
          dataSource={querySetTable}
          className="drop-shadow border rounded"
          style={{ width: "100%", minWidth: "70%", overflowX: "auto", backgroundColor: "unset !important" }}
          columns={columns}
          loading={loading}
          bordered={true}
          pagination={{
            position: ["topRight", "bottomRight"],
          }}

          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MinusCircleTwoTone className="scale-150" onClick={e => onExpand(record, e)} />
              ) : (
                <PlusCircleTwoTone className="scale-150" onClick={e => onExpand(record, e)} />
              ),
            expandedRowRender: (record) => (
              <div className="flex gap-2">
                <div>
                  {record?.chassis_no ? (<div className="border rounded drop-shadow flex justify-center ml-4 mt-3 cursor-pointer" style={{ width: "200px", backgroundColor: "#f7f7f7" }} onClick={() => copyToClipboardModern(record?.chassis_no, "VIN")}>
                    <Tooltip title="Click here to copy the VIN to clipboard">
                      VIN :&nbsp;{record?.chassis_no}
                    </Tooltip>
                  </div>) : (<div className="border rounded drop-shadow flex justify-center ml-4 mt-3 align-middle" style={{ width: "200px" }} >
                    VIN :&nbsp;<i style={{ color: "gray" }}>NOT ADDED</i>
                  </div>)}
                  <div className="image-slider-container drop-shadow m-3" style={{ width: "200px" }}>
                    <Slider {...settings} >
                      {record.image.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            width: "200px",
                            height: "200px",
                            overflow: "hidden",
                            position: "relative"
                          }}
                        >
                          <Image
                            style={{
                              width: "200px",
                              height: "200px",
                              border: "2px solid rgb(206, 203, 203)",
                              borderRadius: "8px",
                              objectFit: "contain"
                            }}
                            src={API_DOMAIN + "/" + item}
                            alt={`${API_DOMAIN}/images/no_img.jpg`}
                            loading="lazy" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <Divider style={{ height: "auto" }} type="vertical" />

                <div className="border rounded m-3 drop-shadow" style={{ maxWidth: "300px", minWidth: "200px" }}>
                  <div style={{ backgroundColor: "#f7f7f7", borderRadius: "8px" }} className="p-1.5 drop-shadow">
                    <div>
                      <Select
                        showSearch
                        size="small"
                        allowClear
                        style={{ width: "209px" }}
                        placeholder="Select a Supplier"
                        optionFilterProp="children"
                        value={
                          record.supplier
                            ? supplierSet.find(item => item.id == record.supplier)?.name || undefined
                            : undefined
                        }
                        onChange={(e) =>
                          handleQueryUpdate(e, true, "", false, "", false, "", false, record.query_id, "", "", "", false, false)
                        }
                      >
                        <option value=""></option>
                        {supplierSet.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div className="pt-1 pl-1" style={{ fontSize: "10px", fontWeight: "540" }}>
                      Pricing Details
                    </div>
                    <div className="border drop-shadow rounded p-1" style={{ backgroundColor: "#fbfbfb" }}>
                      <div>
                        <div style={{ fontSize: "10px" }}>BPrice</div>
                        <InputNumber
                          style={{ width: "100%" }}
                          addonBefore={"AED"}
                          size="small"
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={(e) =>
                            handleBestPriceChange(record?.query_id, e)
                          }
                          onBlur={(e) => handlePriceUpdate(record?.query_id)}
                          value={priceValues[record?.query_id]["best_price_received"] || 0}
                          placeholder="Buying Price"
                        />
                      </div>
                      <div >
                        <Divider className="mt-1.5 mb-1.5" style={{ backgroundColor: "black" }} />
                      </div>
                      <div>
                        <div style={{ fontSize: "10px" }}>Margin Percentage</div>
                        <InputNumber
                          style={{ width: "100%" }}
                          size="small"
                          addonBefore={priceValues[record?.query_id]["price_percentage"] == 0 ? <ImportExport /> : priceValues[record?.query_id]["price_percentage"] > 0 ? <ArrowUpwardIcon style={{ color: "green" }} /> : <ArrowDownwardIcon style={{ color: "red" }} />}
                          addonAfter={"%"}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={(e) =>
                            handleBestPriceChange(record?.query_id, e)
                          }
                          onBlur={(e) => handlePriceUpdate(record?.query_id)}
                          value={priceValues[record?.query_id]["price_percentage"]}
                          placeholder="Margin Percentage"
                        />
                      </div>
                      <div>
                        <div style={{ fontSize: "10px" }}>Delivery Charge</div>
                        <InputNumber
                          style={{ width: "100%" }}
                          size="small"
                          addonBefore={<LocalShippingIcon />}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={(e) =>
                            handleDeliverycostChange(
                              record?.query_id,
                              e
                            )
                          }
                          onBlur={(e) => handlePriceUpdate(record?.query_id)}
                          value={priceValues[record?.query_id]["delivery_cost"]["DeliveryCost"]}
                          placeholder="Delivery Cost"
                        />
                      </div>
                      <div>
                        <Divider className="mt-1.5 mb-1.5" style={{ backgroundColor: "black", }} />
                      </div>
                      <div>
                        <div style={{ fontSize: "10px" }}>Final Price</div>
                        <InputNumber
                          id={"price_given" + record?.query_id}
                          style={{ width: "100%", fontWeight: "700" }}
                          size="small"
                          addonBefore={<SellIcon />}
                          onChange={(e) =>
                            handleGivenPriceChange(record?.query_id, e)
                          }
                          onBlur={(e) => handlePriceUpdate(record?.query_id)}
                          value={priceValues[record?.query_id]["price_given"]}
                          placeholder="Price Given"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Divider style={{ height: "auto" }} type="vertical" />

                <div className="m-3 drop-shadow">
                  <div style={{ backgroundColor: "#f7f7f7", borderRadius: "8px" }} className="p-1.5 drop-shadow">
                    <div>
                      <div className="pl-1" style={{ fontSize: "10px", fontWeight: "540" }}>
                        Miscellaneous Data
                      </div>

                      <div className="rounded">

                        <div style={{ backgroundColor: "#fbfbfb" }} className="drop-shadow border rounded p-1">
                          <div>
                            <div style={{ fontSize: "10px" }}>Query Status</div>
                            <Select
                              showSearch
                              id="status_value"
                              size="small"
                              style={{ width: "209px" }}
                              placeholder="Select a Supplier"
                              optionFilterProp="children"
                              value={priceValues[record?.query_id]["status"]}
                              onChange={(e) => handleQueryUpdate("", false, queryStatusSet.filter(element => element.value == e)[0].label, true, "", false, "", false, record?.query_id, "", "", record?.moredetails, true, false)}
                              options={queryStatusSet}
                            >
                            </Select>
                          </div>
                        </div>

                        <div style={{ backgroundColor: "#fbfbfb" }} className="border drop-shadow rounded p-1 mt-2.5">
                          <div>
                            <div style={{ fontSize: "10px" }}>Remarks</div>
                            <AntdInput
                              allowClear
                              size="small"
                              style={{ width: "209px" }}
                              placeholder="Add a Remark"
                              optionFilterProp="children"
                              value={priceValues[record?.query_id]["remarks"] || undefined}
                              onChange={(e) => handleRemarksChange(record?.query_id, e.target.value)}
                              onBlur={(e) => handleRemarksUpdate(record?.query_id)}
                            >
                            </AntdInput>
                          </div>
                          <div>
                            <div style={{ fontSize: "10px" }}>Follow-up Date</div>
                            <DatePicker
                              size="small"
                              value={priceValues[record?.query_id]["f_date"]}
                              onChange={(e) => handle_f_date_Change(record?.query_id, e)}
                              onBlur={(e) => handle_f_date_Update(record?.query_id)}
                              format={'YYYY-MM-DD'}
                              style={{ width: "209px" }}
                            />
                          </div>
                        </div>

                        <div style={{ backgroundColor: "#fbfbfb" }} className="border drop-shadow rounded p-1 mt-2.5">
                          <div>
                            <div style={{ fontSize: "10px" }}>Warranty</div>
                            <InputNumber
                              style={{ width: "209px" }}
                              size="small"
                              addonAfter={"Days"}
                              onChange={(e) => {
                                handleWarrantyChange(record?.query_id, e);
                                handleWarrantyUpdate(record?.query_id, e);
                              }
                              }
                              value={priceValues[record?.query_id]["warranty"]}
                              placeholder="Warranty in Days"
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <Divider style={{ height: "auto" }} type="vertical" />


                <div className="rounded m-3 drop-shadow">
                  <div style={{ backgroundColor: "#f7f7f7" }} className="p-1.5 drop-shadow rounded">
                    <div>
                      <div className="pl-1" style={{ fontSize: "10px", fontWeight: "540" }}>
                        Quick Actions
                      </div>

                      <div className="bg-transparent rounded ">

                        <div style={{ backgroundColor: "#fbfbfb" }} className="border drop-shadow rounded p-1 mt-1">
                          <div>
                            <div style={{ fontSize: "10px" }}>WATI Actions</div>
                            <div>
                              <Dropdown.Button
                                type='primary'
                                size='small'
                                onClick={() => handleWatiMessages(record)}
                                menu={{ items: hover_menu_items, onClick: (e) => onMenuClick(e, record) }}>WATI Templates</Dropdown.Button>
                            </div>
                            <div className="mt-2">
                              <Tooltip title="Under construction">
                                <Button
                                  type="primary"
                                  size='small'
                                  disabled
                                  onClick={() => handleOpenLinkGen(record)}
                                >Schedule Follow-up</Button>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div style={{ backgroundColor: "#fbfbfb" }} className="border drop-shadow rounded p-1 mt-2.5">
                          <div>
                            <div style={{ fontSize: "10px" }}>Save Additional Info</div>
                            <Tooltip title="Please use the new WATI Templates Quick Actions Option just above.">
                              <Button
                                type="primary"
                                size='small'
                                onClick={() => handleOpenLinkGen(record)}
                              >Open</Button>
                            </Tooltip>
                          </div>
                        </div>

                        <div style={{ backgroundColor: "#fbfbfb" }} className="border drop-shadow rounded p-1 mt-2.5">
                          <div>
                            <div style={{ fontSize: "10px" }}>WhatsApp Details</div>
                            <Button
                              type="primary"
                              size='small'
                              onClick={() => handleOpenLinkGen(record)}
                            >Show Information</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <Divider style={{ height: "auto" }} type="vertical" />

                <div className="rounded m-3 drop-shadow">

                  <Collapse size='small'
                    className="mt-0"
                    style={{ width: "410px", marginTop: "0.3rem" }}
                    accordion
                    defaultActiveKey={2}
                    items={generateAccordionItems(record)} />
                </div>


              </div>
            ),
          }}
        // rowSelection={rowSelection}
        />
      </div>

      {/*Adding query modal*/}
      <AntdModal
        title={modalHeading}
        centered
        loading={false}
        open={open}
        width={1030}
        onCancel={handleClose}
        maskClosable={false}
        footer={<div className="flex justify-between">
          <div id="">
            <Typography>
              Whatsapp Broadcast : {"False"}
            </Typography>
          </div>
          <div id="">
            <Typography>
              Suppliers with possible availability : {suppCount}
            </Typography>
          </div>
          <div>
            {/* <MuiButtonMaterial
              size="small"
              id="send_query_btn"
              variant="contained"
              color="black"
              onClick={(e) => handleSubmit(false)}
            >
              <SaveIcon />
              Save
            </MuiButtonMaterial> */}
            <Button
              sx={{ marginLeft: "10px" }}
              size="small"
              endIcon={<SendOutlinedIcon />}
              id="send_query_btn"
              disabled={isQsDisabled}
              variant="contained"
              style={{ backgroundColor: "green" }}
              onClick={(e) => handleSubmit(true)}
            >
              <SaveOutlined />
              Save & Send
            </Button>
          </div>
        </div>}
      >
        <div>
          <div className="flex justify-between">
            {/* first column*/}
            <div className="border rounded drop-shadow p-3 overflow-y-auto" style={{ height: "390px", maxWidth: "560px" }}>
              <div className="border rounded pt-1 pb-1 mb-1.5" >
                <div style={{ fontSize: "11px", marginLeft: "1rem", transform: "translateY(-15px)", marginBottom: "-16px", background: "white", display: "table", borderRadius: "10px", fontWeight: "700" }}>&nbsp;Supplier Group Section&nbsp;</div>
                <Row className="p-1">
                  <Col>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      optionFilterProp="label"
                      placeholder="Select Supplier Groups"
                      value={make_data}
                      onChange={(e) => set_make_data(e)}
                      options={makeSet}
                    />
                  </Col>
                </Row>
              </div>
              <div className="border rounded pt-1.5 pb-1 mt-3" >
                <div style={{ fontSize: "11px", marginLeft: "1rem", transform: "translateY(-15px)", marginBottom: "-16px", background: "white", display: "table", borderRadius: "10px", fontWeight: "700" }}>&nbsp;Vehicle Details Section&nbsp;</div>
                <Row className="p-1">
                  <Col className="flex justify-between">
                    <Space style={{ width: '100% !important', }}>
                      <Space.Compact style={{ width: '100% !important', }}>
                        <AntdInput
                          allowClear
                          style={{ width: '100%', }}
                          placeholder="Chassis Number (VIN)"
                          maxLength={17}
                          value={chassisno_data}
                          onChange={(e) => {
                            set_chassisno_data(e.target.value.toUpperCase().replaceAll(" ", ""))
                          }}
                        />
                        <Button>
                          <div style={{ fontSize: "11px" }} >
                            <SearchIcon fontSize="extra small" />
                          </div>
                        </Button>
                      </Space.Compact>
                    </Space>
                  </Col>
                  <Col className="flex justify-between">
                    <Space style={{ width: '100% !important', }}>
                      <Space.Compact style={{ width: '100% !important', }}>
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="label"
                          style={{ width: '100% !important', }}
                          filterOption={true}
                          placeholder="Select Make"
                          value={brand_data}
                          onChange={(e) => handleBrandChange(e)}
                          options={brandSet}
                        />
                        <Button
                          onClick={(e) => handleOpenAddModal("Add New Make")}
                        >+</Button>
                      </Space.Compact>
                    </Space>
                  </Col>
                </Row>
                <Row className="p-1">
                  <Col>
                    <Space style={{ width: '100% !important', }}>
                      <Space.Compact style={{ width: '100% !important', }}>
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="label"
                          style={{ width: '100%', }}
                          placeholder="Select Model"
                          value={model_data}
                          onChange={(e) => { handle_model_change(e); setDefaultActiveKey(1); }}
                          options={modelSet}
                        />
                        <Button
                          onClick={(e) => handleOpenAddModal("Add New Model")}
                        >+</Button>
                      </Space.Compact>
                    </Space>
                  </Col>
                  <Col className="flex justify-between">
                    <AntdInput
                      allowClear
                      style={{ width: '100%', }}
                      placeholder="Number of Cylinders"
                      value={cylinder_data}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          set_cylinder_data(e.target.value)
                        }
                      }
                      }
                    />
                  </Col>
                </Row>
                <Row className="p-1">
                  <Col>
                    <AntdInput
                      allowClear
                      style={{ width: '100%', }}
                      placeholder="Additional Model Information"
                      value={model_details_data}
                      onChange={(e) => set_model_details_data(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="p-1">
                  <Col className="flex justify-between">
                    <DatePicker
                      style={{ width: '100%', }}
                      maxDate={dayjs(new Date())}
                      placeholder="Select Year of Make"
                      value={year_data ? dayjs(year_data, "YYYY-MM-DD") : null}
                      onChange={(e) => set_year_data(e)}
                      minDate={dayjs("1990-01-01", "YYYY-MM-DD")}
                      picker="year"
                    />
                  </Col>
                  <Col className="flex justify-between">
                    <Space style={{ width: '100% !important', }}>
                      <Space.Compact style={{ width: '100% !important', }}>
                        <Select
                          allowClear
                          style={{ width: '100%', }}
                          showSearch
                          optionFilterProp="label"
                          placeholder="Select Region"
                          value={region_data}
                          onChange={(e) => handleRegionChange(e)}
                          options={regionDataSet}
                        />
                        <Button
                          onClick={() => handleOpenAddModal("Add New Region")}
                        >+</Button>
                      </Space.Compact>
                    </Space>
                  </Col>
                </Row>
                <Row className="p-1">
                  <Col className="flex justify-between">
                    <Space style={{ width: '100% !important', }}>
                      <Space.Compact style={{ width: '100% !important', }}>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%', }}
                          optionFilterProp="label"
                          placeholder="Parts"
                          value={bodypart_data}
                          onChange={(e) => set_bodypart_data(e)}
                          options={partsDataSet}
                        />
                        <Button
                          onClick={() => handleOpenAddModal("Add New Part")}
                        >+</Button>
                      </Space.Compact>
                    </Space>
                  </Col>
                </Row>
              </div>
              <div className="border rounded pt-1 pb-1 mt-3" >
                <div style={{ fontSize: "11px", marginLeft: "1rem", transform: "translateY(-15px)", marginBottom: "-16px", background: "white", display: "table", borderRadius: "10px", fontWeight: "700" }}>&nbsp;Customer and Enquiry Information Section&nbsp;</div>
                <Row className="p-1">
                  <Col className="flex justify-between">
                    <Space style={{ width: '100% !important', }}>
                      <Space.Compact style={{ width: '100% !important', }}>
                        <Select
                          allowClear
                          style={{ width: '100%', }}
                          showSearch
                          optionFilterProp="label"
                          placeholder="Query Source"
                          value={queryOrigin || null}
                          onChange={(e) => setQueryOrigin(e)}
                          options={queryOriginSet}
                        />
                        <Button
                          onClick={(e) => handleOpenAddModal("Add New Query source")}
                        >+</Button>
                      </Space.Compact>
                    </Space>
                  </Col>
                  <Col className="flex justify-between">
                    <AntdInput
                      allowClear
                      style={{ width: '100% !important', }}
                      placeholder="Customer Phone Number"
                      value={phone_data}
                      onChange={(e) => {
                        const number = e.target.value.replaceAll(" ", "").replace("+", "").replace("(", "").replace(")", "");
                        if (/^\d*$/.test(number)) {
                          handleCusMobChange(number);
                          set_phone_data(number);
                        }
                      }}
                      options={brandSet}
                    />
                  </Col>
                  <Col>
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      style={{ width: '100%', }}
                      placeholder="Customer Name"
                      value={cus_name}
                      loading={cus_name_loading}
                      options={cus_name_set_for_select}
                      onChange={(e) => handleCusNameChange(e)}
                    />
                  </Col>
                </Row>
              </div>

            </div>
            {/* second column*/}
            <div>
              <div className="border rounded p-2 drop-shadow">
                <div style={{ fontSize: "11px", transform: "translateY(-16px)", marginBottom: "-16px", background: "white", display: "table", borderRadius: "10px", fontWeight: "700" }}>&nbsp;Additional WATI Actions&nbsp;</div>
                <div className="flex justify-between">
                  <Row className="p-1">
                    <Col>
                      <AntdCheckbox
                        checked={followupMsg}
                        onChange={(e) => setFollowupMsg(e.target.checked)}
                        style={{ fontSize: "12px" }}
                      >
                        Set Follow-up Messages
                      </AntdCheckbox>
                    </Col>
                  </Row>
                  <Row className="p-1">
                    <Col>
                      <AntdCheckbox
                        checked={sendQidToWati}
                        onChange={(e) => setSendQidToWati(e.target.checked)}
                        style={{ fontSize: "12px" }}
                      >
                        Auto-Send QueryID to customer
                      </AntdCheckbox>
                    </Col>
                  </Row>
                </div>
              </div>
              <Collapse
                size='small'
                className="mt-1.5 drop-shadow"
                style={{ width: "410px", marginTop: "0.3rem" }}
                accordion
                defaultActiveKey={defaultActiveKey}
                items={[
                  {
                    key: 1,
                    label: "Price History",
                    children:
                      <div className="border rounded drop-shadow">
                        <div className="overflow-y-auto flex p-2.5" style={{ height: "230px", width: "100%" }}>

                        </div>
                      </div>
                  },
                  {
                    key: 2,
                    label: "Upload Images",
                    children:
                      <div className="border rounded drop-shadow">
                        <div className="overflow-y-auto flex p-2.5" style={{ height: "230px", width: "100%" }}>
                          <Upload
                            action={`${API_DOMAIN}/whatsapp/image_upload`}
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            onPreview={handlePreview}
                            onChange={handleFileChange}
                          >
                            {fileList.length >= 6 ? null : uploadButton}
                          </Upload>
                          {previewImage && (
                            <Image
                              wrapperStyle={{
                                display: 'none',
                              }}
                              preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                              }}
                              src={previewImage}
                            />
                          )}
                        </div>
                      </div>
                  }
                ]}>
              </Collapse>
            </div>
          </div>

        </div>
      </AntdModal>

      {/*Generate Link modal*/}
      <AntdModal
        title={`Generate a Pay-by-Link Mamopay Link (${dataLink.query_id})`}
        open={openLinkGen}
        onCancel={handleCloseLinkGen}
        centered
        width={550}
        maskClosable={false}
        footer={<div className="flex justify-end">
          <div>
            <Button
              endIcon={<AddLinkIcon />}
              variant="contained"
              style={{ backgroundColor: "green" }}
              onClick={handleLinkGen}
            >
              Request
            </Button>
          </div>
        </div>}>
        <div className="gap-2 grid border rounded drop-shadow p-2" style={{ backgroundColor: "#f7f7f7" }}>
          <div className="flex justify-end" style={{ fontSize: "10px", color: "gray" }}>
            <i>Note : Title can only be 50 characters long.</i>
          </div>
          <div className="border rounded p-2 gap-2 grid" style={{ backgroundColor: "#fbfbfb" }}>
            <div className="flex gap-1">
              <div style={{ width: "180px" }}>Title</div>
              <div>:</div>
              <AntdInput
                maxLength={50}
                placeholder="Title"
                allowClear
                required
                value={linkTitle}
                onChange={(e) => {
                  setLinkTitleHandler(e.target.value);
                }}
              />
            </div>
            <div className="flex gap-1">
              <div style={{ width: "180px" }}>Description</div>
              <div>:</div>
              <AntdInput
                placeholder="Description"
                allowClear
                required
                value={linkDescription}
                onChange={(e) => {
                  setLinkDescriptionHandler(e.target.value);
                }}
              />
            </div>
            <div className="flex gap-1">
              <div style={{ width: "180px" }}>Amount</div>
              <div>:</div>
              <AntdInput
                placeholder="Amount"
                allowClear
                required
                value={linkAmount}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    setLinkAmount(e.target.value);
                  }
                }}
              />
            </div>
            <div className="flex gap-1">
              <div style={{ width: "180px" }}>Processing Fee %</div>
              <div>:</div>
              <AntdInput
                placeholder="Processing Fee %"
                allowClear
                required
                value={linkFeePercent}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    setLinkFeePercent(e.target.value)
                  }
                }}
                addonAfter={"%"}
              />
            </div>
          </div>

          <div className="flex gap-1 justify-center border rounded" style={{ backgroundColor: "#fbfbfb", width: "100%" }}>
            <div style={{ fontWeight: "550", fontSize: "26px" }}>
              {(linkAmount * (1 + linkFeePercent / 100)).toFixed(2) || 0}
            </div>
          </div>

          <div className="border rounded p-2 gap-2 grid" style={{ backgroundColor: "#fbfbfb" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography>Generated Link</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography>{theLink ? theLink : (<i style={{ color: "gray" }}>generate Link First</i>)}</Typography>
            </div>
            <div style={{ height: "7px" }}></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                id="link_gen_send_wati"
                endIcon={<SendOutlinedIcon />}
                variant="contained"
                onClick={handleSendWatiLink}
                style={{ backgroundColor: "green", display: "none" }}
              >
                SEND TO CUSTOMER ON WATI
              </Button>
            </div>
          </div>
        </div>
      </AntdModal >

      {/*make, modal, region etc adding modal*/}
      < Modal
        show={open_add_modal}
        onHide={handleCloseAddModal}
        size="sm"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicText">
              <Form.Control
                type="text"
                placeholder="Enter value"
                value={modal_data}
                // onChange={(e) => set_modal_data(capitalizeWords(e.target.value))}
                onChange={(e) => set_modal_data(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            onClick={(e) => handleModalAdd(e, modalHeading)}
            style={{ backgroundColor: "green" }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal >

      {/*Modal to edit settings*/}
      < SettingsModal
        open_settings={open_settings}
        handleCloseSettingsModal={handleCloseSettingsModal}
        statuses={queryStatusSet}
        save={save_settings}
      />

      <Modal
        show={openQuickReplies}
        onHide={handleCloseQuickReplies}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Use a Quick Response For Query ID : {currentQueryData.query_id} (
            {currentQueryData.cus_name} - {currentQueryData.phone})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Control
              type="text"
              placeholder="Search"
              className="mb-2"
              onChange={search_quickresponses}
            />
            <Tooltip
              title="Write a custom message and send to WATI."
              placement="top"
            >
              <Button
                variant="outline-secondary"
                className="mb-2"
                onClick={() => handleOpenQuickRepliesEdit(null)}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </div>
          <div
            style={{
              fontSize: "12px",
              color: "gray",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <em>
              Total <b>{templateTemp.length}</b> templates.
            </em>
          </div>
          <div
            className="d-flex flex-wrap"
            style={{ height: "350px", overflowY: "auto" }}
          >
            {templateTemp.map((element, index) => (
              <Card
                className="m-2"
                style={{
                  width: "48%",
                  height: "250px",
                  overflowY: "auto",
                  backgroundColor: "#d2d9d7",
                }}
                key={index}
              >
                <div
                  className="flex justify-center"
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    position: "sticky",
                    backgroundColor: "white",
                    zIndex: 10
                  }}
                >
                  <i>
                    Template has been used <b>{element.triggered}</b> times.
                  </i>
                </div>
                <Card.Body>
                  <Card.Title
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {element.name}
                    <Button
                      variant="outline-secondary"
                      className="mr-2"
                      onClick={() => handleOpenQuickRepliesEdit(element.id)}
                    >
                      <SendOutlinedIcon />
                    </Button>
                  </Card.Title>
                  <Card.Text>{element.text}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuickReplies}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        show={openQuickRepliesEdit}
        onHide={handleCloseQuickRepliesEdit}
      >
        <Modal.Header closeButton>
          <Modal.Title>Review message before sending</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* First Column: Table with Data */}
            <div
              className="col-md-4"
              style={{ height: "280px", overflowY: "auto" }}
            >
              <h5>Query Info</h5>
              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: "black",
                  height: "2px",
                  marginTop: "10px",
                  width: "100%",
                  borderRadius: "2px",
                }}
                variant="fullWidth"
                flexItem
              />
              <table>
                <tbody>
                  <tr>
                    <td>Query ID</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.query_id}</td>
                  </tr>
                  <tr>
                    <td>Part required</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.body_part}</td>
                  </tr>
                  <tr>
                    <td>Make</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.make}</td>
                  </tr>
                  <tr>
                    <td>Model</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.model}</td>
                  </tr>
                  <tr>
                    <td>Model Details</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.model_details}</td>
                  </tr>
                  <tr>
                    <td>VIN</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.chassis_no}</td>
                  </tr>
                  <tr>
                    <td>Cylinders</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.cylinder}</td>
                  </tr>
                  <tr>
                    <td>Region</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.region}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Second Column: Textarea */}
            <div className="col-md-4">
              <h5>Message</h5>
              <Form.Control
                as="textarea"
                rows={10}
                placeholder="Type your message here..."
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
              />
            </div>

            {/* Third Column: Another Table with Data */}
            <div className="col-md-4">
              <h5>Customer Info</h5>
              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: "black",
                  height: "2px",
                  marginTop: "10px",
                  width: "100%",
                  borderRadius: "2px",
                }}
                variant="fullWidth"
                flexItem
              />
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>{currentQueryData.cus_name}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>:</td>
                    <td>{currentQueryData.phone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuickRepliesEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendWatiTemplate}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
}

export default Queryset;
