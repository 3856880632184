import { Button, Divider, Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

const settingsOptions = [
    { id: 1, name: "WATI Round-Robbin" },
    { id: 2, name: "Query Status Colors" },
];

const SettingsModal = ({ open_settings, handleCloseSettingsModal, statuses, save }) => {
    const [selectedOption, setSelectedOption] = useState(settingsOptions[0].id);
    const [loading, setLoading] = useState(false);
    const [robbin, setRobbin] = useState(false);
    const [statusColors, setStatusColors] = useState([]);

    useEffect(() => {
        if (statuses && statuses.length > 0) {
            setStatusColors(statuses);
        }
    }, [statuses]);

    const handleMenuClick = (id) => {
        setSelectedOption(id);
    };

    const handleSave = () => {
        save(statusColors);
    };

    const handleColorChange = (id, colour) => {
        setStatusColors(prevStatuses =>
            prevStatuses.map(status =>
                status.value === id ? { ...status, colour } : status
            )
        );
    };

    const handleRobbinToggle = (e) => {
        setRobbin(e)
    }

    const renderDetails = () => {
        switch (selectedOption) {
            case 1:
                return (
                    <div className='p-2 drop-shadow'>
                        <h5>WATI Automated Round-Robbin Ticket Assignment</h5>
                        <Divider type='horizontal' className='p-0 m-0 mt-1 mb-2' style={{ backgroundColor: "black" }} />

                        <div className='border rounded p-2 mt-2 flex justify-between' style={{ backgroundColor: "" }}>
                            <div>Toggle Auto-Assign On/Off</div>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                value={robbin}
                                onChange={handleRobbinToggle}
                            />
                        </div>

                        <div className='border rounded mt-2 p-2'>
                            <div>Set Assignability of Operators</div>
                            <Divider type='horizontal' className='p-0 m-0 mt-1 mb-2' style={{ backgroundColor: "black" }} />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h4>Status Colors</h4>
                        <p>Change the colors of the status</p>
                        <div className='drop-shadow' style={{ display: 'flex', justifyContent: 'space-between', overflowY: "auto", height: "300px" }}>
                            <div>
                                <div className='border rounded p-2 drop-shadow' style={{ backgroundColor: "#fbfbfb", minWidth: "465px" }}>
                                    {statusColors.map((status) => (
                                        <div key={status.value} className='flex justify-between mt-1'>
                                            <div>
                                                <h6>{status.label}</h6>
                                            </div>
                                            <div>
                                                <input
                                                    type="color"
                                                    value={status?.colour || "gray"}
                                                    onChange={(e) => handleColorChange(status.value, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <div>Select a setting from the menu</div>;
        }
    };

    return (
        <Modal
            title="Settings"
            centered
            open={open_settings}
            onCancel={handleCloseSettingsModal}
            maskClosable={false}
            width={800}
            footer={
                <div className="flex justify-end">
                    <Button disabled={loading} type="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </div>}
        >
            <div className='border rounded p-2 drop-shadow' style={{ backgroundColor: "#f7f7f7" }}>
                <div className='drop-shadow flex gap-3' style={{ display: 'flex', height: '400px' }}>
                    {/* Menu Pane */}
                    <div className='border rounded p-2' style={{ width: '200px', backgroundColor: "#fefefe" }}>
                        <div className='flex justify-center'>Menu Items</div>
                        <Divider type='horizontal' className='p-0 m-0 mt-1 mb-2' style={{ backgroundColor: "black" }} />
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {settingsOptions.map(option => (
                                <li key={option.id}
                                    style={{
                                        padding: '10px',
                                        borderRadius: "8px",
                                        cursor: 'pointer',
                                        backgroundColor: selectedOption === option.id ? '#404040' : 'transparent',
                                        transition: "background-color 0.3s, color 0.3s ease-in-out",
                                        color: selectedOption === option.id ? 'white' : 'black',
                                    }}
                                    onClick={() => handleMenuClick(option.id)}>
                                    {option.name}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='m-0' style={{ display: "flex", alignItems: "center", height: "100%" }}>
                        <Divider type='vertical' style={{ backgroundColor: "black", height: "95%" }} />
                    </div>


                    {/* Details Pane */}
                    <div className='border rounded p-2' style={{ backgroundColor: "#fbfbfb", width: "490px" }}>
                        {renderDetails()}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SettingsModal;
